import React from 'react';

const Button = ({ style, children, ...props }) => (
  <button
    type="button"
    style={{
      fontFamily: 'Microsoft YaHei, PingFang SC, Noto Sans CJK, SimHei',
      margin: 0,
      borderStyle: 'none',
      textAlign: 'center',
      cursor: 'pointer',
      ...style
    }}
    {...props}
  >
    {children}
  </button>
);

export default Button;
