import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from './user';
import post from './post';
import usersList from './usersList';
import briefing from './briefing';
import editor from './editor';

export default history => combineReducers({
  router: connectRouter(history),
  user,
  post,
  usersList,
  briefing,
  editor
});
