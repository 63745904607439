import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import store, { history } from 'store';
import { logout } from 'store/actions';
import { getLocal, getSession } from './cache';

axios.defaults.baseURL = '/api/v2';

const isLoginPage = window.location.href.includes('login');

// http response 拦截器
axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 401 清除token信息并跳转到登录页面
          store.dispatch(logout());

          // 只有在当前路由不是登录页面才跳转
          if (!isLoginPage) {
            history.push('/login');
          }
          break;
        default:
      }
    }
    return Promise.reject(error);
  }
);

const request = (method = 'GET', url = '', data = {}) => {
  let result;
  const underscoreData = decamelizeKeys(data);
  const headers = { Authorization: `Bearer ${getLocal('token') || getSession('token')}` };
  switch (method.toUpperCase()) {
    case 'GET':
      result = axios.get(url, {
        params: underscoreData,
        headers
      });
      break;
    default:
      result = axios({
        method,
        url,
        data: underscoreData,
        headers
      });
      break;
  }
  return result.then(camelizeKeys);
};

export default request;
