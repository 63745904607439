import React from 'react';
import PropTypes from 'prop-types';
import deleteBtn from 'assets/icons/delete_blue.svg';
import editBtn from 'assets/icons/edit.svg';
import brandIcon from 'assets/icons/brand.svg';
import grayBrandIcon from 'assets/icons/brand_gray.svg';
import style from './style.scss';

const UsersList = ({ userInfo, isAdmin, ...props }) => (
  <ul className={style.lists}>
    <li
      className={isAdmin ? style['admin-brand'] : style.brand}
      onClick={() => props.setUserPrivilege({
        id: userInfo.id,
        type: isAdmin ? 1 : 2
      })
      }
    >
      <img src={isAdmin ? brandIcon : grayBrandIcon} alt="user" />
    </li>
    <li>{userInfo.id}</li>
    <li>{userInfo.username}</li>
    <li>{userInfo.department}</li>
    <li
      className={style['edit-btn']}
      onClick={() => props.clickEditUserButton({
        showChangeUserModal: true,
        editId: userInfo.id,
        editUsername: userInfo.username,
        editDepartment: userInfo.department
      })
      }
    >
      <img src={editBtn} alt="edit" />
    </li>
    <li
      className={style['delete-btn']}
      onClick={() => props.clickDeleteUserButton({
        showDeleteModal: true,
        deleteUserId: userInfo.id
      })
      }
    >
      <img src={deleteBtn} alt="delete" />
    </li>
  </ul>
);

UsersList.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  clickDeleteUserButton: PropTypes.func.isRequired,
  clickEditUserButton: PropTypes.func.isRequired,
  setUserPrivilege: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    username: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired
  }).isRequired
};

export default UsersList;
