import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserBox } from 'containers';
import robotImg from 'assets/robot.png';
import style from './style.scss';

const Header = props => (
  <header className={style.container}>
    <nav className={style.nav}>
      <Link to="/">首页</Link>
      <NavLink activeClassName={style.active} to={props.link}>
        {props.title}
      </NavLink>
      <UserBox className={style.user} />
    </nav>
    <img src={robotImg} className={style.robot} alt="robot" />
  </header>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default Header;
