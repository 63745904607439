import * as types from 'constants/actionTypes';

const initialState = {
  postsList: [],
  deletedIssue: -1,
  showDeleteModal: false,
  issueList: []
};

const post = (state = initialState, action) => {
  switch (action.type) {
    /* 获取简报期数数组 */
    case types.GET_ISSUE_LIST.REQUEST:
      return state;
    case types.GET_ISSUE_LIST.SUCCESS:
      return {
        ...state,
        issueList: action.response
      };
    case types.GET_ISSUE_LIST.FAILURE:
      return state;

    /* 获取简报列表 */
    case types.GET_BRIEFING_LIST.REQUEST:
      return state;
    case types.GET_BRIEFING_LIST.SUCCESS:
      return {
        ...state,
        postsList: action.response.sort((x, y) => y.totalIssue - x.totalIssue)
      };
    case types.GET_BRIEFING_LIST.FAILURE:
      return state;

    /* 删除简报 */
    case types.CONFIRM_DELETE_BRIEFING:
      return {
        ...state,
        ...action.payload
      };
    case types.DELETE_BRIEFING.REQUEST:
      return state;
    case types.DELETE_BRIEFING.SUCCESS:
      return {
        ...state,
        showDeleteModal: false
      };
    case types.DELETE_BRIEFING.FAILURE:
      return state;
    default:
      return state;
  }
};

export default post;
