import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { Modal } from 'components';
import style from './style.scss';

class SourceCodeBox extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['code', 'preview']).isRequired,
    code: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired
  };

  ref = React.createRef();

  componentDidUpdate(prevProps) {
    if (!this.props.show || this.props.type === 'preview') return;
    this.ref.current.select(); // 每次出现都选中文字
    if (prevProps.code === this.props.code) return; // 只有源码变动才复制到剪切板
    if (copy(this.props.code)) {
      this.props.showMessage({
        status: 'success',
        messageText: '已自动复制到剪切板'
      });
    } else {
      this.props.showMessage({
        status: 'failure',
        messageText: '自动复制到剪切板失败，请手动复制'
      });
    }
  }

  render() {
    return (
      <Modal
        title={this.props.type === 'code' ? '复制源码' : '预览'}
        className={style.container}
        show={this.props.show}
      >
        {this.props.type === 'preview' && (
          <div
            className={style.preview}
            dangerouslySetInnerHTML={{ __html: this.props.code }} /> // eslint-disable-line
        )}
        {this.props.type === 'code' && (
          <textarea
            id="source-code"
            ref={this.ref}
            className={style.code}
            defaultValue={this.props.code}
          />
        )}
        <div className={style.button}>
          <button type="button" onClick={this.props.close}>关闭</button>
        </div>
      </Modal>
    );
  }
}

export default SourceCodeBox;
