import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';
import contactInfo from '../../contactInfo';

const Header = (props) => {
  const dateArray = props.date.split('-');
  const year = dateArray[0];
  const month = dateArray[1];
  const day = dateArray[2];

  return (
    <header
      className={style.container}
      style={{ backgroundImage: `url('${contactInfo.banner}')` }}
    >
      {EDITION === 'qiming' ? (
        <>
          <p className={style['qiming-date']}>{`${year}年${month}月${day}日`}</p>
          <p className={style['qiming-issue']}>{`${year}年  第${props.issue}期  总第${props.totalIssue}期`}</p>
        </>
      ) : (
        <>
          <p className={style['cxcy-issue']}>{`${year}年 第${props.issue}期 总第${props.totalIssue}期`}</p>
          <p className={style['cxcy-date']}>{`${year}年${month}月${day}日`}</p>
        </>
      )}
    </header>
  );
};

Header.propTypes = {
  date: PropTypes.string.isRequired,
  issue: PropTypes.number.isRequired,
  totalIssue: PropTypes.number.isRequired
};

export default Header;
