import React from 'react';
import PropTypes from 'prop-types';

const A = ({
  style, href, children, ...props
}) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    style={{
      fontFamily: 'Microsoft YaHei, PingFang SC, Noto Sans CJK, SimHei',
      textDecoration: 'none',
      ...style
    }}
    {...props}
  >
    {children}
  </a>
);

A.propTypes = {
  href: PropTypes.string.isRequired
};

export default A;
