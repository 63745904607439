import React from 'react';
import PropTypes from 'prop-types';
import { UsersList } from 'components';
import style from './style.scss';

const MembersManage = props => (
  <div className={style.container}>
    <header className={style.header}>
      <ul className={style.lists}>
        <li>学号</li>
        <li>姓名</li>
        <li>院系</li>
      </ul>
    </header>
    <article className={style.article}>
      {props.usersList
        .sort((x, y) => y.type - x.type)
        .map(userInfo => (
          <UsersList
            key={userInfo.id}
            isAdmin={userInfo.type === 2}
            clickDeleteUserButton={props.clickDeleteUserButton}
            clickEditUserButton={props.clickEditUserButton}
            setUserPrivilege={props.setUserPrivilege}
            userInfo={userInfo}
          />
        ))}
    </article>
  </div>
);

MembersManage.propTypes = {
  clickDeleteUserButton: PropTypes.func.isRequired,
  clickEditUserButton: PropTypes.func.isRequired,
  setUserPrivilege: PropTypes.func.isRequired,
  usersList: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      type: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
      department: PropTypes.string.isRequired
    })
  ).isRequired
};

export default MembersManage;
