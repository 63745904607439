import * as types from 'constants/actionTypes';
import {
  getLocal,
  setLocal,
  removeLocal,
  setSession,
  removeSession
} from 'utils/cache';

const initialState = {
  message: '',
  rememberStatus: getLocal('rememberStatus') || false,
  username: '',
  type: 0,
  id: '',
  department: '',
  showChangePwdDialog: false
};

const user = (state = initialState, action) => {
  switch (action.type) {
    /* 登录相关 */
    case types.LOGIN.REQUEST:
      return {
        ...state,
        message: ''
      };
    case types.LOGIN.SUCCESS:
      // if (state.rememberStatus) {
      //   removeSession('token');
      //   setLocal('token', action.response.token);
      // } else {
      //   removeLocal('token');
      //   setSession('token', action.response.token);
      // }
      setLocal('token', action.response.token);
      return {
        ...state,
        type: action.response.type,
        username: action.response.username
      };
    case types.LOGIN.FAILURE:
      removeLocal('token');
      removeSession('token');
      return {
        ...state,
        message: action.error.message
      };
    case types.LOGOUT:
      removeLocal('token');
      removeSession('token');
      return state;

      /* 记住密码 */
    case types.REMEMBER_LOGIN_STATUS:
      setLocal('rememberStatus', action.payload.rememberStatus);
      return {
        ...state,
        rememberStatus: action.payload.rememberStatus
      };

      /* 获取用户信息 */
    case types.GET_USER_INFO.REQUEST:
      return state;
    case types.GET_USER_INFO.SUCCESS:
      return {
        ...state,
        ...action.response
      };
    case types.GET_USER_INFO.FAILURE:
      return state;

      /* 修改密码 */
    case types.CONFIRM_CHANGE_PASSWORD:
      return {
        ...state,
        showChangePwdDialog: action.payload.state,
        message: ''
      };
    case types.CHANGE_PASSWORD.REQUEST:
      return {
        ...state,
        message: ''
      };
    case types.CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        showChangePwdDialog: false
      };
    case types.CHANGE_PASSWORD.FAILURE:
      return {
        ...state,
        message: action.error.message
      };
    default:
      return state;
  }
};

export default user;
