import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from './style.scss';

const ComboBox = props => (
  <div className={`${style.container} ${props.className}`}>
    <ul className={style.lists}>
      {props.type === 2 ? (
        <>
          <li className={style.content}>
            <Link to="/users">成员管理</Link>
          </li>
          <li className={style.separator} />
        </>
      ) : null}
      <li className={style.content}>
        <Link to="/profile">个人资料</Link>
      </li>
      <li className={style.separator} />
      <li className={style.content} onClick={props.logout}>
        退出
      </li>
    </ul>
  </div>
);

ComboBox.propTypes = {
  className: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  logout: PropTypes.func.isRequired
};

export default ComboBox;
