import React from 'react';
import PropTypes from 'prop-types';
import { Box, Item } from 'react-html-email';
import mapFontSize from 'utils/mapFontSize';
import { Component, A, Img, P } from './base';

const LeftTextRightPicture = props => (
  <Component
    style={{
      backgroundColor: '#e7e9ef'
    }}
  >
    <Item
      style={{
        padding: '24px 20px'
      }}
    >
      <Box
        style={{
          width: '460px',
          marginRight: '20px'
        }}
      >
        <Item>
          <A href={props.link}>
            {props.title.split('\n').map(sentence => (
              <P
                style={{
                  color: '#323b6f',
                  fontSize: mapFontSize(props.titleFontSize) || '22px',
                  fontWeight: 'bold'
                }}
              >
                {sentence}
              </P>
            ))}
          </A>
        </Item>
        <Item
          style={{
            paddingTop: '10px'
          }}
        >
          {props.text.split('\n').map(sentence => (
            <P
              style={{
                fontSize: mapFontSize(props.textFontSize) || '18px',
                color: '#333'
              }}
            >
              {sentence}
            </P>
          ))}
        </Item>
      </Box>
      <Box>
        <Item
          style={{
            width: '200px',
            height: '140px'
          }}
        >
          <Img
            src={props.imgSrc}
            alt="cover"
            width={200}
            height={140}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </Item>
      </Box>
    </Item>
  </Component>
);

LeftTextRightPicture.propTypes = {
  title: PropTypes.string.isRequired,
  titleFontSize: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textFontSize: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default LeftTextRightPicture;
