import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UserBox } from 'containers';
import robotImg from 'assets/robot.png';
import style from './style.scss';

const EditorHeader = props => (
  <header className={style.container}>
    <nav className={style.nav}>
      <Link to="/">首页</Link>
      <UserBox className={style.user} />
    </nav>
    <img src={robotImg} className={style.robot} alt="robot" />
    <div className={style.buttons}>
      <button type="button" onClick={props.showEmailBox}>发送邮件</button>
      <button type="button" onClick={props.clickExternalLinkButton}>查看外链</button>
      <button type="button" onClick={props.clickResetButton}>重置</button>
      <button type="button" onClick={props.clickPreviewButton}>预览</button>
      <button type="button" onClick={props.clickCopyButton}>复制源码</button>
    </div>
  </header>
);

EditorHeader.propTypes = {
  showEmailBox: PropTypes.func.isRequired,
  clickExternalLinkButton: PropTypes.func.isRequired,
  clickResetButton: PropTypes.func.isRequired,
  clickPreviewButton: PropTypes.func.isRequired,
  clickCopyButton: PropTypes.func.isRequired
};

export default EditorHeader;
