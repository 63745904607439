/**
 * 移动数组的某一项，返回一个新的数组
 * @param {Array} array - 要移动的数组
 * @param {number} currentIndex - 当前数组下标
 * @param {number} targetIndex - 要移动位置的下标
 * @returns {Array} new array - 返回新的数组
 */
function moveArrayItem(array, currentIndex, targetIndex) {
  if (
    currentIndex < 0 ||
    currentIndex >= array.length ||
    targetIndex < 0 ||
    targetIndex >= array.length
  ) {
    return array;
  }
  const result = array.slice();
  result.splice(currentIndex, 1);
  result.splice(targetIndex, 0, array[currentIndex]);
  return result;
}

export default moveArrayItem;
