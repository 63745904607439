import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import deleteBtn from 'assets/icons/delete_white.svg';
import arrowBtn from 'assets/icons/arrow.svg';
import style from './style.scss';
import { getLocal, setLocal } from '../../utils/cache';

const Card = props => (
  <section className={style.container}>
    <header className={style.header}>
      <h2>第{props.postInfo.totalIssue}期</h2>
      <button
        type="button"
        className={style['delete-btn']}
        onClick={() =>
          props.clickDeleteBriefingButton(true, props.postInfo.totalIssue)
        }
      >
        <img src={deleteBtn} alt="delete" />
      </button>
    </header>
    <Link
      className={style.article}
      to={{
        pathname: 'edit',
        search: qs.stringify({
          total: props.postInfo.totalIssue
        })
      }}
      target="_blank"
    >
      <div className={style.content}>
        <div className={style.row}>
          <h3 className={style.cell}>创建者：</h3>
          <span className={style.cell}>{props.postInfo.creator}</span>
        </div>
        <div className={style.row}>
          <h3 className={style.cell}>最近更新：</h3>
          <span className={style.cell}>
            {props.postInfo.modifyTime &&
              props.postInfo.modifyTime.split(' ')[0]}{' '}
            {props.postInfo.modifier}
          </span>
        </div>
      </div>
      <button className={style.button} type="button">
        <img className={style['enter-icon']} src={arrowBtn} alt="enter" />
      </button>
    </Link>
  </section>
);

Card.propTypes = {
  postInfo: PropTypes.shape({
    totalIssue: PropTypes.number.isRequired,
    creator: PropTypes.string.isRequired,
    modifier: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    modifyTime: PropTypes.string.isRequired
  }).isRequired,
  clickDeleteBriefingButton: PropTypes.func.isRequired
};

export default Card;
