import React from 'react';
import PropTypes from 'prop-types';
import { Item } from 'react-html-email';
import mapFontSize from 'utils/mapFontSize';
import { Component, A, P } from './base';

const PlainText = props => (
  <Component>
    <Item
      align="center"
      style={{
        borderLeft: '6px solid #5c75ff',
        padding: '6px 0 20px 28px'
      }}
    >
      <A href={props.link}>
        {props.title.split('\n').map(sentence => (
          <P
            style={{
              textAlign: 'left',
              fontSize: mapFontSize(props.titleFontSize) || '22px',
              fontWeight: 'bold',
              color: '#323b6f'
            }}
          >
            {sentence}
          </P>
        ))}
      </A>
      {props.text.split('\n').map(sentence => (
        <P
          style={{
            margin: '15px 0 0 24px',
            textAlign: 'left',
            fontSize: mapFontSize(props.textFontSize) || '18px',
            color: '#333'
          }}
        >
          {sentence}
        </P>
      ))}
    </Item>
  </Component>
);

PlainText.propTypes = {
  title: PropTypes.string.isRequired,
  titleFontSize: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textFontSize: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default PlainText;
