import React from 'react';
import { Box, Item } from 'react-html-email';
import TopPictureBottomTextOneColumn from './TopPictureBottomTextOneColumn';

const TopPictureBottomTextTwoColumns = props => (
  <Box>
    <Item>
      <TopPictureBottomTextOneColumn
        {...props.children[0]}
        align={props.align}
        column={2}
        style={{
          marginRight: '20px'
        }}
      />
      <TopPictureBottomTextOneColumn
        {...props.children[1]}
        align={props.align}
        column={2}
      />
    </Item>
  </Box>
);

export default TopPictureBottomTextTwoColumns;
