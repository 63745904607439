import React from 'react';
import * as editor from './editor';
import * as mail from './mail';

let globalMode = '';

const setMode = (newMode) => {
  if (globalMode === newMode) return;
  if (!['editor', 'mail'].includes(newMode)) {
    throw new Error('Mode must be one of ["editor", "mail"]!');
  }
  globalMode = newMode;
};

const mapMode = mode => ({ editor, mail }[mode]);

const parseName = (initialName) => {
  let align = null;
  if (initialName.includes('Align')) {
    align = initialName.match(/.*(Left|Center)Align.*/)[1].toLowerCase();
  }
  const name = initialName.replace(/(Left|Center)Align/, '');
  return {
    align,
    name
  };
};

const renderComponent = (config, index, parentIndex = -1) => {
  if (config.type !== 'component') return null;
  const { name, align } = parseName(config.name);
  const Component = mapMode(globalMode)[name];
  if (!Component) return null;
  const key = config.name + index;
  const props = {
    ...config,
    key,
    index,
    parentIndex: globalMode === 'editor' ? parentIndex : null
  };
  switch (name) {
    case 'Banner':
    case 'Card':
    case 'LeftTextRightPicture':
    case 'PlainText':
    case 'TopicLink':
      return (
        <Component {...props} />
      );
    case 'TopPictureBottomTextOneColumn':
      return (
        <Component {...props} align={align} />
      );
    default:
      return null;
  }
};

const renderModule = (config, index) => {
  if (config.type !== 'module') return null;
  const Module = mapMode(globalMode)[config.name];
  if (!Module) return null;
  const key = config.name + index;
  const props = {
    ...config,
    index: globalMode === 'editor' ? index : null,
    key: globalMode === 'editor' ? null : key // editor不需要key是因为在外面又包了一层
  };
  if (config.children && config.children.length > 0) {
    return (
      <Module {...props}>
        {config.children.map((component, i) => renderComponent(component, i, index))}
      </Module>
    );
  }
  // 在邮件中如果模块内没有组件就不用渲染
  return globalMode === 'editor' ? <Module {...props} /> : null;
};

const renderCompositeComponent = (config, index) => {
  if (config.type !== 'compositeComponent') return null;
  const { name, align } = parseName(config.name);
  const CompositeComponent = mapMode(globalMode)[name];
  if (!CompositeComponent) return null;
  const key = config.name + index;
  const props = {
    ...config,
    index: globalMode === 'editor' ? index : null,
    key: globalMode === 'editor' ? null : key, // editor不需要key是因为在外面又包了一层
    align
  };
  return (
    <CompositeComponent
      {...props}
    />
  );
};

export default (mode, item, index) => {
  setMode(mode);
  switch (item.type) {
    case 'component':
      return renderComponent(item, index);
    case 'module':
      return renderModule(item, index);
    case 'compositeComponent':
      return renderCompositeComponent(item, index);
    default:
      return null;
  }
};
