import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { InputNumber, InputDate, SettingMenu } from 'components';
import { changeBriefingConfig } from 'store/actions';

class SettingPanel extends React.Component {
  static mapStateToProps = state => ({
    issue: state.briefing.config.issue,
    totalIssue: state.briefing.config.totalIssue,
    date: state.briefing.config.date
  });

  static mapDispatchToProps = dispatch => ({
    changeBriefingConfig: payload => dispatch(changeBriefingConfig(payload))
  });

  static propTypes = {
    issue: PropTypes.number.isRequired,
    totalIssue: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    changeBriefingConfig: PropTypes.func.isRequired
  };

  // 取整和取大于0的值
  formatValue = value => (parseInt(value, 10) >= 1 ? parseInt(value, 10) : 1);

  handleIssueChange = (e) => {
    const issue = this.formatValue(e.target.value);
    if (issue > this.props.totalIssue) return;
    this.props.changeBriefingConfig({
      issue
    });
  };

  handleTotalIssueChange = (e) => {
    const totalIssue = this.formatValue(e.target.value);
    if (totalIssue < this.props.issue) return;
    this.props.changeBriefingConfig({
      totalIssue
    });
  };

  handleDateChange = (date) => {
    this.props.changeBriefingConfig({
      date: date.format('YYYY-MM-DD')
    });
  };

  subtractIssue = () => {
    this.props.changeBriefingConfig({
      issue: this.props.issue - 1
    });
  };

  addIssue = () => {
    if (this.props.issue + 1 > this.props.totalIssue) return;
    this.props.changeBriefingConfig({
      issue: this.props.issue + 1
    });
  };

  subtractTotalIssue = () => {
    if (this.props.totalIssue - 1 < this.props.issue) return;
    this.props.changeBriefingConfig({
      totalIssue: this.props.totalIssue - 1
    });
  };

  addTotalIssue = () => {
    this.props.changeBriefingConfig({
      totalIssue: this.props.totalIssue + 1
    });
  };

  render() {
    return (
      <SettingMenu
        issueComponent={(
          <InputNumber
            value={this.props.issue}
            handleChange={this.handleIssueChange}
            add={this.addIssue}
            subtract={this.subtractIssue}
          />
        )}
        totalIssueComponent={(
          <InputNumber
            value={this.props.totalIssue}
            handleChange={this.handleTotalIssueChange}
            add={this.addTotalIssue}
            subtract={this.subtractTotalIssue}
          />
        )}
        dateComponent={
          <InputDate value={moment(this.props.date)} handleChange={this.handleDateChange} />
        }
      />
    );
  }
}

export default connect(
  SettingPanel.mapStateToProps,
  SettingPanel.mapDispatchToProps
)(SettingPanel);
