import { createActionTypes } from '@twcn/redux-tools';

/* ---------- 多重状态 ---------- */

/* 账户操作 */
export const LOGIN = createActionTypes('LOGIN');
export const GET_USER_INFO = createActionTypes('GET_USER_INFO');
export const CHANGE_PASSWORD = createActionTypes('CHANGE_PASSWORD');

/* 用户管理 */
export const GET_USERS_LIST = createActionTypes('GET_USERS_LIST');
export const ADD_USER = createActionTypes('ADD_USER');
export const DELETE_USER = createActionTypes('DELETE_USER');
export const CHANGE_USER_INFO = createActionTypes('CHANGE_USER_INFO');
export const SET_USER_PRIVILEGE = createActionTypes('SET_USER_PRIVILEGE');

/* 简报 */
export const GET_ISSUE_LIST = createActionTypes('GET_ISSUE_LIST');
export const GET_BRIEFING_LIST = createActionTypes('GET_BRIEFING_LIST');
export const GET_BRIEFING_CONTENT = createActionTypes('GET_BRIEFING_CONTENT');
export const SAVE_BRIEFING = createActionTypes('SAVE_BRIEFING');
export const DELETE_BRIEFING = createActionTypes('DELETE_BRIEFING');

/* 编辑器界面消息提示 */
export const SHOW_MESSAGE = createActionTypes('SHOW_MESSAGE');

/* 发送邮件 */
export const SEND_EMAIL = createActionTypes('SEND_EMAIL');

/* ---------- 单一状态 ---------- */

export const REMEMBER_LOGIN_STATUS = 'REMEMBER_LOGIN_STATUS';
export const LOGOUT = 'LOGOUT';

/* 弹窗相关 */
export const CONFIRM_DELETE_BRIEFING = 'CONFIRM_DELETE_BRIEFING';
export const CONFIRM_CHANGE_PASSWORD = 'CONFIRM_CHANGE_PASSWORD';
export const CONFIRM_DELETE_USER = 'CONFIRM_DELETE_USER';
export const CONFIRM_ADD_USER = 'CONFIRM_ADD_USER';
export const CONFIRM_CHANGE_USER = 'CONFIRM_CHANGE_USER';
export const EDIT_USER_INFO = 'EDIT_USER_INFO';
export const SHOW_EMAIL_BOX = 'SHOW_EMAIL_BOX';

/* 编辑简报内容相关 */
export const NEW_BRIEFING = 'NEW_BRIEFING';
export const ADD_ELEMENT = 'ADD_ELEMENT';
export const DELETE_ELEMENT = 'DELETE_ELEMENT';
export const MOVE_ELEMENT = 'MOVE_ELEMENT';
export const EDIT_MODULE = 'EDIT_MODULE';
export const CHANGE_BRIEFING_CONFIG = 'CHANGE_BRIEFING_CONFIG';
export const CHANGE_BRIEFING_CONTENT = 'CHANGE_BRIEFING_CONTENT';
export const RESET_BRIEFING_CONTENT = 'RESET_BRIEFING_CONTENT';
export const SHOW_SOURCE_CODE = 'SHOW_SOURCE_CODE';
