import React from 'react';
import PropTypes from 'prop-types';
import { Item } from 'react-html-email';
import isLocalhost from 'utils/isLocalhost';
import { Module, Img, P } from './base';

const ColorfulBorder = props => (
  <Module>
    <Item>
      {props.title.split('\n').map(sentence => (
        <P
          style={{
            padding: '4px 20px',
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#323b6f',
            textAlign: 'center'
          }}
        >
          {sentence}
        </P>
      ))}
    </Item>
    <Item>
      <Img
        src={`${
          isLocalhost(window.location.origin)
            ? 'https://dev.newbriefing.hustonline.net'
            : window.location.origin
        }/static/top_separator.png`}
        alt="separator"
        width={660}
        height={17}
        style={{
          width: '100%',
          height: '17px'
        }}
      />
    </Item>
    <Item>{props.children}</Item>
    <Item
      style={{
        borderTop: '1px solid #5c75ff'
      }}
    >
      <div
        style={{
          margin: '0 auto',
          width: '40%',
          height: '10px',
          borderBottom: '1px solid #5c75ff'
        }}
      />
    </Item>
  </Module>
);

ColorfulBorder.defaultProps = {
  children: null
};

ColorfulBorder.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default ColorfulBorder;
