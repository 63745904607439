import React from 'react';
import PropTypes from 'prop-types';
import successIcon from 'assets/icons/check.svg';
import failureIcon from 'assets/icons/cross.svg';
import style from './style.scss';

const Message = props => (
  <div
    className={`${style.container} ${props.showMessage || style.hide}`}
    style={{ backgroundColor: props.status === 'success' ? '#fff' : '#fb7253' }}
  >
    <img
      className={style.icon}
      src={props.status === 'success' ? successIcon : failureIcon}
      alt="icon"
    />
    <p className={props.status === 'success' ? style.success : style.failure}>
      {props.messageText}
    </p>
  </div>
);

Message.propTypes = {
  showMessage: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  messageText: PropTypes.string.isRequired
};

export default Message;
