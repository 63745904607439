import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteElement, editModule } from 'store/actions';
import deleteBtn from 'assets/icons/bin.svg';
import style from './style.scss';

const Module = props => (
  <div
    className={style.container}
    id={`element-1-${props.index}`} // 为了给添加元素时自动滚动定位
  >
    <ul className={style.menu}>
      <li onClick={() => props.deleteElement(props.index, -1)}>
        <img src={deleteBtn} alt="delete" />
        删除
      </li>
    </ul>
    {props.children}
    <button
      type="button"
      className={
        props.activeModuleIndex === props.index ? style.active : style.normal
      }
      onClick={() =>
        props.editModule({
          state: props.activeModuleIndex !== props.index,
          index: props.index
        })
      }
    >
      {props.activeModuleIndex === props.index ? '完成编辑' : '编辑模块'}
    </button>
  </div>
);

Module.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  activeModuleIndex: PropTypes.number.isRequired,
  editModule: PropTypes.func.isRequired,
  deleteElement: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  activeModuleIndex: state.briefing.activeModuleIndex
});

const mapDispatchToProps = dispatch => ({
  editModule: payload => dispatch(editModule(payload)),
  deleteElement: (index, parentIndex) =>
    dispatch(deleteElement({ index, parentIndex }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Module);
