import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';

const ProfileCard = props => (
  <div className={style.container}>
    <header className={style.header}>
      <span className={style.title}>姓名：</span>
      <span className={style.content}>{props.username}</span>
    </header>
    <article className={style.article}>
      <div className={style.row}>
        <span className={style.title}>学号：</span>
        <span className={style.content}>{props.id}</span>
      </div>
      <div className={style.row}>
        <span className={style.title}>院系：</span>
        <span className={style.content}>{props.department}</span>
      </div>
      <button type="button" onClick={props.onClick} className={style.button}>
        修改密码
      </button>
    </article>
  </div>
);

ProfileCard.propTypes = {
  username: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  department: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ProfileCard;
