import React from 'react';
import PropTypes from 'prop-types';
import { Box, Item } from 'react-html-email';
import isLocalhost from 'utils/isLocalhost';
import { P } from './base';
import contactInfo from '../contactInfo';

const Header = (props) => {
  const dateArray = props.date.split('-');
  const year = dateArray[0];
  const month = dateArray[1];
  const day = dateArray[2];

  return (
    <Box
      style={{
        width: '100%',
        height: '140px',
        marginBottom: '30px',
        background: `url(${
          isLocalhost(window.location.origin)
            ? 'https://dev.newbriefing.hustonline.net'
            : window.location.origin
        }${contactInfo.banner}) no-repeat center center`,
        backgroundSize: '100% 100%'
      }}
    >
      {EDITION === 'qiming' ? (
        <Item
          style={{
            padding: '36px 60px 0 0'
          }}
        >
          <P
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#17477c',
              textAlign: 'right'
            }}
          >
            {`${year}年${month}月${day}日`}
          </P>
          <P
            style={{
              marginTop: '4px',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#17477c',
              textAlign: 'right'
            }}
          >
            {`${year}年  第${props.issue}期  总第${props.totalIssue}期`}
          </P>
        </Item>
      ) : (
        <Item
          style={{
            padding: '88px 28px 0 0'
          }}
        >
          <P
            style={{
              fontSize: '14px',
              color: '#fff',
              textAlign: 'right'
            }}
          >
            {`${year}年 第${props.issue}期 总第${props.totalIssue}期`}
          </P>
          <P
            style={{
              marginTop: '4px',
              fontSize: '14px',
              color: '#fff',
              textAlign: 'right'
            }}
          >
            {`${year}年${month}月${day}日`}
          </P>
        </Item>
      )}
    </Box>
  );
};

Header.propTypes = {
  date: PropTypes.string.isRequired,
  issue: PropTypes.number.isRequired,
  totalIssue: PropTypes.number.isRequired
};

export default Header;
