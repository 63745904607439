import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components';
import style from './style.scss';

const CardsContainer = props => (
  <div className={style.container}>
    <div className={style.warp}>
      {props.postsList.map(postInfo => (
        <Card
          postInfo={postInfo}
          key={postInfo.totalIssue}
          clickDeleteBriefingButton={props.clickDeleteBriefingButton}
        />
      ))}
    </div>
  </div>
);

CardsContainer.propTypes = {
  postsList: PropTypes.arrayOf(
    PropTypes.shape({
      totalIssue: PropTypes.number.isRequired,
      creator: PropTypes.string.isRequired,
      modifier: PropTypes.string.isRequired,
      createTime: PropTypes.string.isRequired,
      modifyTime: PropTypes.string.isRequired
    })
  ).isRequired,
  clickDeleteBriefingButton: PropTypes.func.isRequired
};

export default CardsContainer;
