import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import { changeBriefingContent } from 'store/actions';
import mapFontSize from 'utils/mapFontSize';
import { InputText } from 'components';
import editBtn from 'assets/icons/pen_blue.svg';
import style from './style.scss';
import Cropper from '../Cropper';
import FontSize from '../FontSize';

class TopPictureBottomTextCenterAlignBase extends React.Component {
  static mapDispatchToProps = dispatch => ({
    changeBriefingContent: payload => dispatch(changeBriefingContent(payload))
  });

  static propTypes = {
    className: PropTypes.string,
    align: PropTypes.string,
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    parentIndex: PropTypes.number.isRequired,
    title: PropTypes.string,
    titleFontSize: PropTypes.string,
    text: PropTypes.string,
    textFontSize: PropTypes.string,
    date: PropTypes.string,
    imgSrc: PropTypes.string,
    link: PropTypes.string,
    column: PropTypes.number,
    changeBriefingContent: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: '',
    align: 'left',
    title: '',
    titleFontSize: 'H3',
    text: '',
    textFontSize: 'C2',
    date: '',
    imgSrc: '',
    link: '',
    column: 1
  };

  state = {
    showLinkBox: false,
    link: this.props.link
  };

  handleChange = (e) => {
    this.props.changeBriefingContent({
      parentIndex: this.props.parentIndex,
      index: this.props.index,
      content: {
        [e.target.name]: e.target.value
      }
    });
  };

  handleLinkChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  renderLinkBox = () => {
    if (this.props.column === 1) {
      return null;
    }
    return (
      <div>
        <ul className={style.menu}>
          <li onClick={() => this.setState({ showLinkBox: true })}>
            <img src={editBtn} alt="edit link" />
            修改链接
          </li>
        </ul>
        {this.state.showLinkBox && (
          <div className={style['link-box']}>
            <InputText name="link" value={this.state.link} onChange={this.handleLinkChange} />
            <div className={style.buttons}>
              <button
                type="button"
                className={style.cancel}
                onClick={() => this.setState({ showLinkBox: false, link: this.props.link })}
              >
                取消
              </button>
              <button
                type="button"
                className={style.confirm}
                onClick={() => {
                  this.setState({ showLinkBox: false });
                  this.props.changeBriefingContent({
                    parentIndex: this.props.parentIndex,
                    index: this.props.index,
                    content: {
                      link: this.state.link
                    }
                  });
                }}
              >
                确定
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className={`${style.container} ${this.props.className}`}>
        {this.renderLinkBox()}
        <label
          htmlFor={this.props.name + this.props.parentIndex + this.props.index}
          className={style.label}
        >
          <div
            className={style.cover}
            style={{
              backgroundImage: `url("${this.props.imgSrc}")`,
              backgroundColor: this.props.imgSrc ? '#fff' : null
            }}
          />
        </label>
        <div className={style.content}>
          <FontSize {...this.props} className={style['title-wrap']} fontSize={this.props.titleFontSize}>
            <Textarea
              name="title"
              onChange={this.handleChange}
              value={this.props.title}
              className={style.title}
              placeholder="请输入标题"
              style={{
                textAlign: this.props.align,
                fontSize: mapFontSize(this.props.titleFontSize)
              }}
            />
          </FontSize>
          <FontSize {...this.props} className={style['text-wrap']} fontSize={this.props.textFontSize}>
            <Textarea
              name="text"
              onChange={this.handleChange}
              value={this.props.text}
              className={style.text}
              placeholder="请输入描述文本"
              style={{ fontSize: mapFontSize(this.props.textFontSize) }}
            />
          </FontSize>
          {this.props.align === 'left' && (
            <input
              name="date"
              value={this.props.date}
              onChange={this.handleChange}
              className={style.date}
              type="text"
              placeholder="日期时间"
            />
          )}
        </div>
        <Cropper
          id={this.props.name + this.props.parentIndex + this.props.index}
          aspectRatio={18 / 5 / this.props.column}
          confirm={imgSrc => this.props.changeBriefingContent({
            parentIndex: this.props.parentIndex,
            index: this.props.index,
            content: { imgSrc }
          })
          }
        />
      </div>
    );
  }
}

export default connect(
  null,
  TopPictureBottomTextCenterAlignBase.mapDispatchToProps
)(TopPictureBottomTextCenterAlignBase);
