import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';

const Modal = props => props.show && (
  <div className={style.mask}>
    <div className={`${style.container} ${props.className}`}>
      <header className={style.header}>
        <h1 className={style.title}>{props.title}</h1>
      </header>
      <div className={style.body}>{props.children}</div>
    </div>
  </div>
);

Modal.defaultProps = {
  show: false,
  title: '提示',
  className: ''
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string
};

export default Modal;
