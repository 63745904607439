import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createHistory from 'history/createHashHistory';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension'; // eslint-disable-line
import { setLocal } from 'utils/cache';
import interceptor from 'utils/interceptor';
import createRootReducer from './reducers';
import rootSaga from './sagas';

export const history = createHistory();
const middleware = routerMiddleware(history);

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  createRootReducer(history),
  composeWithDevTools(
    applyMiddleware(interceptor, sagaMiddleware, middleware)
  )
);

/* 自动保存简报内容到本地 */
let currentValue = '';
let lastTime = null;
store.subscribe(() => {
  const previousValue = currentValue;
  currentValue = store.getState();
  // 简报内容变动时且是新建的简报才存储到本地
  if (previousValue.briefing !== currentValue.briefing && currentValue.editor.isNew) {
    const nowTime = new Date();
    if (!lastTime || nowTime - lastTime > 1000) {
      setLocal('briefing', store.getState().briefing);
      lastTime = nowTime;
    }
  }
});

sagaMiddleware.run(rootSaga);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./reducers', () => {
    const nextRootReducer = require('./reducers'); // eslint-disable-line
    store.replaceReducer(nextRootReducer.default);
  });
}

export default store;
