/*
  这里是所有组件的列表，用于渲染组件菜单的内容
  如果需要新增组件，先在这里添加名字和类别
  再分别去editor和mail目录下添加相同name的组件即可
 */

const defaultLink = ((edition) => {
  switch (edition) {
    case 'cxcy':
      return 'http://cxcy.hust.edu.cn';
    case 'qiming':
      return 'http://qiming.hust.edu.cn';
    default:
      return 'http://www.hust.edu.cn';
  }
})(EDITION);

// content里为必填的属性,除此之外未列出的是可选属性
export const components = [
  {
    description: '横幅头图',
    name: 'Banner',
    content: {
      title: '',
      titleFontSize: 'H1', // 默认值
      text: '',
      textFontSize: 'C2',
      imgSrc: '',
      link: defaultLink
    }
  }, {
    description: '链接组件',
    name: 'TopicLink',
    content: {
      title: '',
      titleFontSize: 'H3',
      link: defaultLink
    }
  }, {
    description: '纯文本',
    name: 'PlainText',
    content: {
      title: '',
      titleFontSize: 'H3',
      text: '',
      textFontSize: 'C2',
      link: defaultLink
    }
  }, {
    description: '卡片式',
    name: 'Card',
    content: {
      title: '',
      titleFontSize: 'H3',
      text: '',
      textFontSize: 'C2',
      date: '',
      imgSrc: '',
      link: defaultLink
    }
  }, {
    description: '左文右图',
    name: 'LeftTextRightPicture',
    content: {
      title: '',
      titleFontSize: 'H3',
      text: '',
      textFontSize: 'C2',
      imgSrc: '',
      link: defaultLink
    }
  }
];

export const compositeComponents = [
  {
    description: '上图下文',
    subtitle: '左对齐',
    content: [
      {
        title: '一行一个',
        type: 'component',
        name: 'TopPictureBottomTextLeftAlignOneColumn',
        content: {
          title: '',
          titleFontSize: 'H3',
          text: '',
          textFontSize: 'C2',
          date: '',
          imgSrc: '',
          link: defaultLink
        }
      }, {
        title: '一行两个',
        type: 'compositeComponent',
        name: 'TopPictureBottomTextLeftAlignTwoColumns'
      }, {
        title: '一行三个',
        type: 'compositeComponent',
        name: 'TopPictureBottomTextLeftAlignThreeColumns'
      }
    ]
  }, {
    description: '上图下文',
    subtitle: '居中',
    content: [
      {
        title: '一行一个',
        type: 'component',
        name: 'TopPictureBottomTextCenterAlignOneColumn'
      }, {
        title: '一行两个',
        type: 'compositeComponent',
        name: 'TopPictureBottomTextCenterAlignTwoColumns'
      }, {
        title: '一行三个',
        type: 'compositeComponent',
        name: 'TopPictureBottomTextCenterAlignThreeColumns'
      }
    ]
  }
];

export const modules = [
  {
    description: '朴素模块',
    name: 'SimpleModule'
  }, {
    description: '彩色边框',
    name: 'ColorfulBorder'
  }, {
    description: '灰色背景',
    name: 'GrayBackground'
  }
];
