export default function mapFontSize(param) {
  switch (param) {
    case 'H1':
      return 36;
    case 'H2':
      return 28;
    case 'H3':
      return 22;
    case 'H4':
      return 20;
    case 'H5':
      return 18;
    case 'C1':
      return 20;
    case 'C2':
      return 18;
    case 'C3':
      return 16;
    case 'C4':
      return 14;
    default:
      return null;
  }
}
