import React from 'react';
import { UserBox } from 'containers';
import logo from 'assets/logo.png';
import style from './style.scss';

const HomeHeader = () => (
  <header className={style.header}>
    <nav className={style.nav}>
      <img src={logo} alt="logo" className={style.logo} />
      <h1 className={style.title}>首页</h1>
      <UserBox className={style.user} />
    </nav>
    { EDITION === 'test' && <h1 className={style.warning} id="warning">这是简报的测试环境，没看到这句话就要小心了！</h1> }
  </header>
);

export default HomeHeader;
