import React from 'react';
import style from './style.scss';
import contactInfo from '../../contactInfo';

const Footer = () => (
  <footer className={style.container}>
    <img className={style.qrcode} src={contactInfo.qrcode} alt="qrcode" />
    <div className={style.introduction}>
      <div className={style.header}>
        <h1 className={style.title}>{contactInfo.name}</h1>
        <h2 className={style.address}>{contactInfo.location}</h2>
      </div>
      <div className={style.footer}>
        <dl className={style.contact}>
          <dt>邮编：</dt>
          <dd>{contactInfo.postcode}</dd>
          <dt>电话：</dt>
          <dd>{contactInfo.phone.join(' ')}</dd>
          <dt>官网：</dt>
          <dd>{contactInfo.website.replace(/^.*:\/\//, '')}</dd>
          <dt>邮箱：</dt>
          <dd>{contactInfo.email}</dd>
        </dl>
        <div className={style.copyright}>
          POWERED BY&nbsp;
          <img className={style.logo} src="/static/bingyan_logo.png" alt="bingyan" />
          Bingyan Studio
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
