import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import arrowIcon from 'assets/icons/right_triangle.svg';
import deleteBtn from 'assets/icons/bin.svg';
import editBtn from 'assets/icons/pen_blue.svg';
import { InputText } from 'components';
import {
  deleteElement,
  moveElement,
  changeBriefingContent
} from 'store/actions';
import style from './style.scss';

class Component extends React.Component {
  static mapDispatchToProps = dispatch => ({
    deleteElement: (index, parentIndex) =>
      dispatch(deleteElement({ index, parentIndex })),
    moveElement: (parentIndex, index, destination) =>
      dispatch(moveElement({ parentIndex, index, destination })),
    changeBriefingContent: payload => dispatch(changeBriefingContent(payload))
  });

  static propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    parentIndex: PropTypes.number.isRequired,
    link: PropTypes.string,
    deleteElement: PropTypes.func.isRequired,
    moveElement: PropTypes.func.isRequired,
    changeBriefingContent: PropTypes.func.isRequired
  };

  static defaultProps = {
    link: ''
  };

  state = {
    showLinkBox: false,
    link: this.props.link
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { index, parentIndex } = this.props;

    return (
      <div
        className={style.container}
        id={`element${parentIndex}-${index}`} // 为了给添加元素时自动滚动定位
      >
        <div className={style.component}>
          <ul className={style.menu}>
            <li
              onClick={() =>
                this.props.moveElement(parentIndex, index, index - 1)
              }
            >
              <img src={arrowIcon} alt="up" />
            </li>
            <li
              onClick={() =>
                this.props.moveElement(parentIndex, index, index + 1)
              }
            >
              <img src={arrowIcon} alt="down" />
            </li>
            <li onClick={() => this.props.deleteElement(index, parentIndex)}>
              <img src={deleteBtn} alt="delete" />
              删除
            </li>
            <li onClick={() => this.setState({ showLinkBox: true })}>
              <img src={editBtn} alt="edit link" />
              修改链接
            </li>
          </ul>
          {this.state.showLinkBox && (
            <div className={style['link-box']}>
              <InputText
                name="link"
                value={this.state.link}
                onChange={this.handleChange}
              />
              <div className={style.buttons}>
                <button
                  type="button"
                  className={style.cancel}
                  onClick={() =>
                    this.setState({ showLinkBox: false, link: this.props.link })
                  }
                >
                  取消
                </button>
                <button
                  type="button"
                  className={style.confirm}
                  onClick={() => {
                    this.setState({ showLinkBox: false });
                    this.props.changeBriefingContent({
                      parentIndex,
                      index,
                      content: {
                        link: this.state.link
                      }
                    });
                  }}
                >
                  确定
                </button>
              </div>
            </div>
          )}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default connect(null, Component.mapDispatchToProps)(Component);
