import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';
import CompositeComponent from '../CompositeComponent';
import TopPictureBottomTextBase from '../TopPictureBottomTextBase';

const TopPictureBottomTextTwoColumns = props => (
  <CompositeComponent index={props.index}>
    <div className={style.container}>
      <TopPictureBottomTextBase
        {...props.children[0]}
        className={style.left}
        parentIndex={props.index}
        index={0}
        column={2}
        name={props.name}
        align={props.align}
      />
      <TopPictureBottomTextBase
        {...props.children[1]}
        parentIndex={props.index}
        index={1}
        column={2}
        name={props.name}
        align={props.align}
      />
    </div>
  </CompositeComponent>
);

TopPictureBottomTextTwoColumns.defaultProps = {
  children: []
};

TopPictureBottomTextTwoColumns.propTypes = {
  name: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string
    })
  )
};

export default TopPictureBottomTextTwoColumns;
