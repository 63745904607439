import React from 'react';
import { Box, Item } from 'react-html-email';
import isLocalhost from 'utils/isLocalhost';
import {
  A, P, Span, Img
} from './base';
import contactInfo from '../contactInfo';

const Footer = () => (
  <Box
    style={{
      width: '100%',
      height: '180px',
      marginTop: '30px',
      backgroundColor: '#171b33'
    }}
  >
    <Item
      style={{
        padding: '15px 70px'
      }}
    >
      <Box>
        <Item
          style={{
            width: '150px',
            height: '150px'
          }}
        >
          <Img
            src={
              (isLocalhost(window.location.origin)
                ? 'https://dev.newbriefing.hustonline.net'
                : window.location.origin) + contactInfo.qrcode
            }
            alt="qrcode"
            width={150}
            height={150}
            style={{
              width: '150px',
              height: '150px'
            }}
          />
        </Item>
      </Box>
      <Box
        style={{
          width: '478px',
          height: '150px',
          marginLeft: '30px'
        }}
      >
        <Item
          style={{
            verticalAlign: 'top'
          }}
        >
          <h1
            style={{
              margin: 0,
              fontFamily: 'Microsoft YaHei, PingFang SC, Noto Sans CJK, SimHei',
              fontSize: '20px',
              fontWeight: 'bold',
              color: '#fff'
            }}
          >
            {contactInfo.name}
          </h1>
          <h2
            style={{
              marginTop: '6px',
              fontFamily: 'Microsoft YaHei, PingFang SC, Noto Sans CJK, SimHei',
              fontSize: '12px',
              fontWeight: 'lighter',
              color: '#d0d1d6'
            }}
          >
            {contactInfo.location}
          </h2>
        </Item>
        <Item
          style={{
            verticalAlign: 'bottom'
          }}
        >
          <Box
            style={{
              width: '100%'
            }}
          >
            <tr>
              <td>
                <P>
                  <Span
                    style={{
                      color: '#d0d1d6',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      paddingRight: '10px'
                    }}
                  >
                    邮编：
                  </Span>
                  <Span
                    style={{
                      color: '#a2a4ad',
                      fontSize: '12px'
                    }}
                  >
                    {contactInfo.postcode}
                  </Span>
                </P>
                <P>
                  <Span
                    style={{
                      color: '#d0d1d6',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      paddingRight: '10px'
                    }}
                  >
                    电话：
                  </Span>
                  {contactInfo.phone.map(tel => (
                    <A
                      key={tel}
                      href={`tel:${tel}`}
                      style={{
                        color: '#a2a4ad',
                        fontSize: '12px',
                        marginRight: '3px'
                      }}
                    >
                      {tel}
                    </A>
                  ))}
                </P>
                <P>
                  <Span
                    style={{
                      color: '#d0d1d6',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      paddingRight: '10px'
                    }}
                  >
                    官网：
                  </Span>
                  <A
                    href={contactInfo.website}
                    style={{
                      color: '#a2a4ad',
                      fontSize: '12px'
                    }}
                  >
                    {contactInfo.website.replace(/^.*:\/\//, '')}
                  </A>
                </P>
                <P>
                  <Span
                    style={{
                      color: '#d0d1d6',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      paddingRight: '10px'
                    }}
                  >
                    邮箱：
                  </Span>
                  <A
                    href={`mailto:${contactInfo.email}`}
                    style={{
                      color: '#a2a4ad',
                      fontSize: '12px'
                    }}
                  >
                    {contactInfo.email}
                  </A>
                </P>
              </td>
              <td
                style={{
                  verticalAlign: 'bottom'
                }}
              >
                <P
                  style={{
                    fontSize: '12px',
                    color: '#fff',
                    textAlign: 'right'
                  }}
                >
                  POWERED BY&nbsp;
                  <A
                    href="https://www.bingyan.net/"
                    style={{
                      fontSize: '12px',
                      color: '#fff'
                    }}
                  >
                    <Img
                      src={`${
                        isLocalhost(window.location.origin)
                          ? 'https://dev.newbriefing.hustonline.net'
                          : window.location.origin
                      }/static/bingyan_logo.png`}
                      alt="bingyan-taowei"
                      width={12}
                      height={12}
                      style={{
                        display: 'inline-block',
                        width: '12px',
                        height: '12px',
                        marginBottom: '-2px',
                        overflow: 'hidden'
                      }}
                    />
                    Bingyan Studio
                  </A>
                </P>
              </td>
            </tr>
          </Box>
        </Item>
      </Box>
    </Item>
  </Box>
);

export default Footer;
