import React from 'react';
import PropTypes from 'prop-types';
import eyeIcon from 'assets/icons/eyes.svg';
import eyeClosedIcon from 'assets/icons/eyes_closed.svg';
import style from './style.scss';

class Password extends React.Component {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  state = {
    showPassword: false
  };

  changePasswordShowing = (e) => {
    e.stopPropagation();
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  };

  render() {
    return (
      <div className={this.props.className}>
        <div className={style.container}>
          <input
            placeholder="密码"
            {...this.props}
            className={style.password}
            type={this.state.showPassword ? 'text' : 'password'}
          />
          <button
            type="button"
            className={style.button}
            tabIndex="-1"
            onClick={this.changePasswordShowing}
          >
            <img src={this.state.showPassword ? eyeClosedIcon : eyeIcon} alt="see" />
          </button>
        </div>
      </div>
    );
  }
}

export default Password;
