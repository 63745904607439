import React from 'react';

const P = ({ style, children, ...props }) => (
  <p
    style={{
      margin: 0,
      fontFamily: 'Microsoft YaHei, PingFang SC, Noto Sans CJK, SimHei',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      lineHeight: '1.2',
      ...style
    }}
    {...props}
  >
    {children}
  </p>
);

export default P;
