import React from 'react';
import PropTypes from 'prop-types';
import { Password, InputText } from 'components';
import checkboxIcon from 'assets/icons/checkbox.svg';
import checkedCheckboxIcon from 'assets/icons/checked_checkbox.svg';
import style from './style.scss';

function switchTitle(edition) {
  switch (edition) {
    case 'cxcy':
      return '创新创业简报系统';
    case 'qiming':
      return '启明学院简报系统';
    case 'test':
      return '简报系统测试环境';
    default:
      return '简报系统';
  }
}

const Login = props => (
  <div className={style.container}>
    <div className={style['login-box']}>
      <h1 className={style.title}>{switchTitle(EDITION)}
      </h1>
      <p className={style.warning}>{props.warningMessage}</p>
      <form className={style.form} onSubmit={props.submit}>
        <InputText
          name="id"
          className={style.username}
          placeholder="学号"
          value={props.id}
          onChange={props.handleChange}
          autoComplete="off"
        />
        <Password
          name="password"
          className={style.password}
          value={props.password}
          onChange={props.handleChange}
          autoComplete="off"
        />
        <div className={style.remember} onClick={props.handleCheckClick}>
          <img
            className={style.checkboxIcon}
            src={props.rememberStatus ? checkedCheckboxIcon : checkboxIcon}
            alt="checkbox"
          />
          &nbsp;记住登录状态
        </div>
        <input className={style.submit} type="submit" value="登录" />
      </form>
    </div>
  </div>
);

Login.propTypes = {
  rememberStatus: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  warningMessage: PropTypes.string.isRequired,
  handleCheckClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default Login;
