import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Login } from 'components';
import { login, rememberLoginStatus } from 'store/actions';

class LoginPage extends React.Component {
  static mapStateToProps = state => ({
    rememberStatus: state.user.rememberStatus,
    warningMessage: state.user.message
  });

  static mapDispatchToProps = dispatch => ({
    login: payload => dispatch(login.request(payload)),
    rememberLoginStatus: rememberStatus => dispatch(rememberLoginStatus({ rememberStatus }))
  });

  static propTypes = {
    rememberStatus: PropTypes.bool.isRequired,
    rememberLoginStatus: PropTypes.func.isRequired,
    warningMessage: PropTypes.string.isRequired,
    login: PropTypes.func.isRequired
  };

  state = {
    id: '',
    password: ''
  };

  handleCheckClick = () => {
    this.props.rememberLoginStatus(!this.props.rememberStatus);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trim()
    });
  };

  submit = (e) => {
    e.preventDefault();
    this.props.login({
      id: this.state.id,
      password: this.state.password
    });
  };

  render() {
    return (
      <Login
        id={this.state.id}
        password={this.state.password}
        warningMessage={this.props.warningMessage}
        rememberStatus={this.props.rememberStatus}
        handleCheckClick={this.handleCheckClick}
        handleChange={this.handleChange}
        submit={this.submit}
      />
    );
  }
}

export default connect(
  LoginPage.mapStateToProps,
  LoginPage.mapDispatchToProps
)(LoginPage);
