import React from 'react';
import PropTypes from 'prop-types';
import mapFontSize from 'utils/mapFontSize';
import {
  Component, A, Span, Button
} from './base';

const TopicLink = props => (
  <Component
    style={{
      height: '32px'
    }}
  >
    <tr
      style={{
        width: '100%',
        height: '24px',
        verticalAlign: 'middle'
      }}
    >
      <td
        style={{
          verticalAlign: 'middle'
        }}
      >
        <div
          style={{
            width: '24px',
            height: '24px',
            backgroundColor: '#5c75ff'
          }}
        />
      </td>
      <td
        style={{
          width: '568px',
          height: '24px',
          padding: '0 20px',
          lineHeight: '24px',
          verticalAlign: 'middle'
        }}
      >
        <Span
          style={{
            color: '#000',
            fontSize: mapFontSize(props.titleFontSize) || '22px',
            fontWeight: 'bold'
          }}
        >
          {props.title}
        </Span>
      </td>
      <td
        style={{
          verticalAlign: 'middle'
        }}
      >
        <A
          href={props.link}
        >
          <Button
            style={{
              padding: '6px 16px',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#fff',
              backgroundColor: '#5c75ff'
            }}
          >
            了解更多
          </Button>
        </A>
      </td>
    </tr>
  </Component>
);

TopicLink.propTypes = {
  title: PropTypes.string.isRequired,
  titleFontSize: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default TopicLink;
