import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeBriefingContent } from 'store/actions';
import Module from '../Module';
import style from './style.scss';

const GrayBackground = (props) => {
  const handleChange = (e) => {
    props.changeBriefingContent({
      parentIndex: -1,
      index: props.index,
      content: {
        [e.target.name]: e.target.value
      }
    });
  };

  return (
    <Module index={props.index}>
      <div className={style.container}>
        <input
          type="text"
          name="title"
          className={style.title}
          placeholder="请输入模块名称"
          value={props.title}
          onChange={handleChange}
          autoComplete="off"
        />
        <div className={style.decoration} />
        <section className={style.body}>{props.children}</section>
      </div>
    </Module>
  );
};

GrayBackground.defaultProps = {
  children: null,
  title: ''
};

GrayBackground.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  title: PropTypes.string,
  changeBriefingContent: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  changeBriefingContent: payload => dispatch(changeBriefingContent(payload))
});

export default connect(
  null,
  mapDispatchToProps
)(GrayBackground);
