import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import { changeBriefingContent } from 'store/actions';
import mapFontSize from 'utils/mapFontSize';
import style from './style.scss';
import Component from '../Component';
import FontSize from '../FontSize';

class PlainText extends React.Component {
  static mapDispatchToProps = dispatch => ({
    changeBriefingContent: payload => dispatch(changeBriefingContent(payload))
  });

  static propTypes = {
    index: PropTypes.number.isRequired,
    parentIndex: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    titleFontSize: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textFontSize: PropTypes.string.isRequired,
    changeBriefingContent: PropTypes.func.isRequired
  };

  handleChange = (e) => {
    this.props.changeBriefingContent({
      parentIndex: this.props.parentIndex,
      index: this.props.index,
      content: {
        [e.target.name]: e.target.value
      }
    });
  };

  render() {
    return (
      <Component {...this.props}>
        <div className={style.container}>
          <FontSize {...this.props} className={style['title-wrap']} fontSize={this.props.titleFontSize}>
            <Textarea
              name="title"
              className={style.title}
              placeholder="请输入标题"
              value={this.props.title}
              onChange={this.handleChange}
              style={{ fontSize: mapFontSize(this.props.titleFontSize) }}
            />
          </FontSize>
          <FontSize {...this.props} className={style['text-wrap']} fontSize={this.props.textFontSize}>
            <Textarea
              name="text"
              className={style.text}
              placeholder="请输入描述文本"
              value={this.props.text}
              onChange={this.handleChange}
              style={{ fontSize: mapFontSize(this.props.textFontSize) }}
            />
          </FontSize>
        </div>
      </Component>
    );
  }
}

export default connect(
  null,
  PlainText.mapDispatchToProps
)(PlainText);
