import React from 'react';
import PropTypes from 'prop-types';
import arrowIcon from 'assets/icons/right_triangle.svg';
import style from './style.scss';

class FontSize extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    fontSize: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    parentIndex: PropTypes.number.isRequired,
    changeBriefingContent: PropTypes.func.isRequired
  };

  state = {
    showFontSizeBox: false,
    type: this.props.fontSize.includes('H') ? 'title' : 'text'
  };

  setFontSize = (e) => {
    this.setState({
      showFontSizeBox: false
    });
    this.props.changeBriefingContent({
      parentIndex: this.props.parentIndex,
      index: this.props.index,
      content: {
        [`${this.state.type}FontSize`]: e.target.innerText
      }
    });
  };

  render() {
    return (
      <div className={`${style.container} ${this.props.className}`}>
        <div className={`${style.menu} ${this.state.showFontSizeBox ? style.active : ''}`}>
          <div className={style.current}>
            <p className={style.text}>{this.props.fontSize}</p>
            <button className={style.button} type="button">
              <img src={arrowIcon} alt="arrow" onClick={() => this.setState(prevState => ({ showFontSizeBox: !prevState.showFontSizeBox }))} />
            </button>
          </div>
          <div className={style.lists}>
            {this.state.type === 'title' && (
              <>
                <button className={style.list} onClick={this.setFontSize} type="button">H1</button>
                <button className={style.list} onClick={this.setFontSize} type="button">H2</button>
                <button className={style.list} onClick={this.setFontSize} type="button">H3</button>
                <button className={style.list} onClick={this.setFontSize} type="button">H4</button>
                <button className={style.list} onClick={this.setFontSize} type="button">H5</button>
              </>
            )}
            {this.state.type === 'text' && (
              <>
                <button className={style.list} onClick={this.setFontSize} type="button">C1</button>
                <button className={style.list} onClick={this.setFontSize} type="button">C2</button>
                <button className={style.list} onClick={this.setFontSize} type="button">C3</button>
                <button className={style.list} onClick={this.setFontSize} type="button">C4</button>
              </>
            )}
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default FontSize;
