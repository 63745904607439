import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBriefingContent, getIssueList } from 'store/actions';
import renderHtml from '../renderMail';

class NewsPage extends React.Component {
  static mapStateToProps = state => ({
    issueList: state.post.issueList,
    briefing: state.briefing
  });

  static mapDispatchToProps = dispatch => ({
    getIssueList: () => dispatch(getIssueList.request()),
    getBriefingContent: payload => dispatch(getBriefingContent.request(payload))
  });

  static propTypes = {
    getIssueList: PropTypes.func.isRequired,
    getBriefingContent: PropTypes.func.isRequired,
    issueList: PropTypes.arrayOf(PropTypes.number).isRequired,
    briefing: PropTypes.shape({
      config: PropTypes.shape({
        issue: PropTypes.number.isRequired,
        totalIssue: PropTypes.number.isRequired
      }).isRequired,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ).isRequired
    }).isRequired
  };


  state = {
    selectedIssue: this.props.match.params.totalIssue // eslint-disable-line
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.totalIssue !== prevState.selectedIssue) {
      return {
        selectedIssue: nextProps.match.params.totalIssue
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.getIssueList();
    this.props.getBriefingContent({
      totalIssue: this.props.match.params.totalIssue // eslint-disable-line
    });
  }

  handleChange = (event) => {
    this.props.history.replace(`/news/${event.target.value}`); // eslint-disable-line
    this.props.getBriefingContent({
      totalIssue: event.target.value
    });
  };

  render() {
    return (
      <main
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {this.props.issueList.indexOf(parseInt(this.props.match.params.totalIssue, 10)) > -1
          ? <>
            <header>
              请选择第
              <select value={this.state.selectedIssue} onChange={this.handleChange}>
                {this.props.issueList.map(
                  issue => <option value={issue}>{issue}</option>
                )}
              </select>
              期简报
            </header>
            <article
              dangerouslySetInnerHTML={{ __html: renderHtml(this.props.briefing) }} // eslint-disable-line
            />
          </>
          : '请先保存后再打开外链'
        }
      </main>
    );
  }
}

export default connect(
  NewsPage.mapStateToProps,
  NewsPage.mapDispatchToProps
)(NewsPage);
