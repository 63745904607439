import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import moment from 'moment';
import style from './style.scss';

const InputDate = props => (
  <div className={style.container}>
    <DatePicker
      className={style.input}
      selected={props.value}
      onChange={props.handleChange}
      dateFormat="YYYY-MM-DD"
    />
  </div>
);

InputDate.propTypes = {
  value: PropTypes.instanceOf(moment).isRequired,
  handleChange: PropTypes.func.isRequired
};

export default InputDate;
