import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';

class EditorAside extends React.Component {
  static propTypes = {
    content: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired
      })
    ).isRequired
  };

  state = {
    activeIndex: 0
  };

  handleClick = (index) => {
    this.setState({
      activeIndex: index
    });
  };

  render() {
    return (
      <aside className={style.container}>
        <ul className={style.lists}>
          {this.props.content.map((item, index) => (
            <li
              onClick={() => {
                this.handleClick(index);
              }}
              key={item.title}
              className={this.state.activeIndex === index ? style.active : ''}
            >
              {item.title}
            </li>
          ))}
        </ul>
        <div className={style.float}>{this.props.content[this.state.activeIndex].children}</div>
      </aside>
    );
  }
}

export default EditorAside;
