import React from 'react';
import PropTypes from 'prop-types';
import { Box, Item } from 'react-html-email';
import mapFontSize from 'utils/mapFontSize';
import { A, Span, P, Img } from './base';

const TopPictureBottomTextOneColumn = props => {
  let width = 720;
  switch (props.column) {
    case 2:
      width = 350;
      break;
    case 3:
      width = 230;
      break;
    default:
  }

  return (
    <Box
      style={{
        width,
        margin: '25px 0',
        ...props.style
      }}
    >
      <Item>
        <Img
          src={props.imgSrc}
          alt="cover"
          width={width}
          height={200}
          style={{
            width: '100%',
            height: '200px'
          }}
        />
      </Item>
      <Item
        style={{
          padding: '20px 20px 0'
        }}
      >
        <A href={props.link}>
          {props.title.split('\n').map(sentence => (
            <P
              style={{
                fontSize: mapFontSize(props.titleFontSize) || '22px',
                fontWeight: 'bold',
                color: '#323b6f',
                textAlign: props.align
              }}
            >
              {sentence}
            </P>
          ))}
        </A>
        {props.text.split('\n').map(sentence => (
          <P
            style={{
              minHeight: '50px',
              marginTop: '10px',
              fontSize: mapFontSize(props.textFontSize) || '18px',
              color: '#333'
            }}
          >
            {sentence}
          </P>
        ))}

        {props.align === 'left' && (
          <Span
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#c73936'
            }}
          >
            {props.date}
          </Span>
        )}
      </Item>
    </Box>
  );
};

TopPictureBottomTextOneColumn.propTypes = {
  column: PropTypes.number.isRequired,
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['left', 'center']).isRequired,
  title: PropTypes.string.isRequired,
  titleFontSize: PropTypes.oneOf(['H1', 'H2', 'H3', 'H4', 'H5']).isRequired,
  text: PropTypes.string.isRequired,
  textFontSize: PropTypes.oneOf(['C1', 'C2', 'C3', 'C4']).isRequired,
  date: PropTypes.string.isRequired,
  style: PropTypes.object // eslint-disable-line
};

export default TopPictureBottomTextOneColumn;
