import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ComponentMenu } from 'components';
import { addElement } from 'store/actions';
import { components, compositeComponents, modules } from '../componentConfig';

const ComponentPanel = props => (
  <ComponentMenu
    content={{
      module: modules.map(module => ({
        description: module.description,
        onClick: () => props.addElement({ type: 'module', name: module.name })
      })),
      component: components.map(component => ({
        description: component.description,
        onClick: () => props.addElement({ type: 'component', name: component.name, ...component.content })
      })),
      compositeComponent: compositeComponents.map(compositeComponent => ({
        description: compositeComponent.description,
        subtitle: compositeComponent.subtitle,
        content: compositeComponent.content.map((item) => {
          if (item.type !== 'compositeComponent') {
            return {
              title: item.title,
              onClick: () => props.addElement({
                type: item.type,
                name: item.name,
                ...item.content
              })
            };
          }
          return {
            title: item.title,
            onClick: () => props.addElement({
              type: item.type,
              name: item.name,
              children: []
            })
          };
        })
      }))
    }}
  />
);

ComponentPanel.propTypes = {
  addElement: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  addElement: payload => dispatch(addElement(payload))
});

export default connect(
  null,
  mapDispatchToProps
)(ComponentPanel);
