import React from 'react';
import Component from '../Component';
import TopPictureBottomTextBase from '../TopPictureBottomTextBase';

const TopPictureBottomTextOneColumn = props => (
  <Component {...props}>
    <TopPictureBottomTextBase {...props} />
  </Component>
);

export default TopPictureBottomTextOneColumn;
