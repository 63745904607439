import React from 'react';
import PropTypes from 'prop-types';
import rightTriangle from 'assets/icons/right_triangle.svg';
import style from './style.scss';

class DropDownButton extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    activeImgSrc: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
      })
    ).isRequired
  };

  state = {
    showMenu: false
  };

  showMenu = () => {
    this.setState(prevState => ({
      showMenu: !prevState.showMenu
    }));
  };

  render() {
    return (
      <div className={`${style.container} ${this.state.showMenu ? style.active : ''}`}>
        <h1 className={style.title}>{this.props.title}</h1>
        <h2 className={style.subtitle}>
          {this.props.subtitle}
          <img src={this.state.showMenu ? this.props.activeImgSrc : this.props.imgSrc} alt="icon" />
        </h2>
        <button className={style.button} onClick={this.showMenu} type="button">
          <img src={rightTriangle} alt="arrow" />
        </button>
        <ul className={style.menu}>
          {this.props.content.reduce((result, info, index) => {
            if (index === 0) {
              return result.concat(
                <li
                  className={style.li}
                  onClick={() => {
                    this.showMenu();
                    info.onClick();
                  }}
                  key={info.title}
                >
                  {info.title}
                </li>
              );
            }
            return result.concat(
              <React.Fragment key={info.title}>
                <li className={style.separator} />
                <li
                  className={style.li}
                  onClick={() => {
                    this.showMenu();
                    info.onClick();
                  }}
                >
                  {info.title}
                </li>
              </React.Fragment>
            );
          }, [])}
        </ul>
      </div>
    );
  }
}

export default DropDownButton;
