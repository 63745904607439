import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { User } from 'components';
import { getUserInfo, logout } from 'store/actions';

class UserBox extends React.Component {
  static mapStateToProps = state => ({
    username: state.user.username,
    type: state.user.type
  });

  static mapDispatchToProps = dispatch => ({
    getUserInfo: () => dispatch(getUserInfo.request()),
    logout: () => {
      dispatch(logout());
      dispatch(push('/login'));
    }
  });

  static propTypes = {
    username: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    className: PropTypes.string,
    getUserInfo: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  state = {
    showComboBox: false
  };

  componentDidMount() {
    this.props.getUserInfo();
  }

  openComboBox = () => {
    this.setState(prevState => ({
      showComboBox: !prevState.showComboBox
    }));
  };

  render() {
    return (
      <User
        showComboBox={this.state.showComboBox}
        className={this.props.className}
        username={this.props.username}
        type={this.props.type}
        onClick={this.openComboBox}
        logout={this.props.logout}
      />
    );
  }
}

export default connect(
  UserBox.mapStateToProps,
  UserBox.mapDispatchToProps
)(UserBox);
