import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import arrowIcon from 'assets/icons/right_triangle.svg';
import deleteBtn from 'assets/icons/bin.svg';
import { deleteElement, moveElement } from 'store/actions';
import style from './style.scss';

const CompositeComponent = ({ index, ...props }) => (
  <div className={style.container} id={`element-1-${index}`}>
    {' '}
    {/* 为了给添加元素时自动滚动定位 */}
    <div className={style.component}>
      <ul className={style.menu}>
        <li onClick={() => props.moveElement(-1, index, index - 1)}>
          <img src={arrowIcon} alt="up" />
        </li>
        <li onClick={() => props.moveElement(-1, index, index + 1)}>
          <img src={arrowIcon} alt="down" />
        </li>
        <li onClick={() => props.deleteElement(index, -1)}>
          <img src={deleteBtn} alt="delete" />
          删除
        </li>
      </ul>
      {props.children}
    </div>
  </div>
);

const mapDispatchToProps = dispatch => ({
  deleteElement: (index, parentIndex) =>
    dispatch(deleteElement({ index, parentIndex })),
  moveElement: (parentIndex, index, destination) =>
    dispatch(moveElement({ parentIndex, index, destination }))
});

CompositeComponent.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  deleteElement: PropTypes.func.isRequired,
  moveElement: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(CompositeComponent);
