import React from 'react';
import PropTypes from 'prop-types';
import rightTriangle from 'assets/icons/right_triangle.svg';
import style from './style.scss';

const InputNumber = props => (
  <div className={style.container}>
    <button
      type="button"
      onClick={props.subtract}
      style={{ visibility: props.value > 1 ? 'visible' : 'hidden' }}
    >
      <img src={rightTriangle} alt="minus" />
    </button>
    <input type="text" value={props.value} onChange={props.handleChange} />
    <button type="button" onClick={props.add}>
      <img src={rightTriangle} alt="plus" />
    </button>
  </div>
);

InputNumber.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  subtract: PropTypes.func.isRequired
};

export default InputNumber;
