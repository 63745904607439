import request from 'utils/request';

const GET = 'GET';
const POST = 'POST';

export const login = data => request(POST, 'users.login', data);

export const getUserInfo = () => request(GET, 'users.get');

export const getUsersList = () => request(GET, 'users.patchGet');

export const addUser = data => request(POST, 'users.create', data);

export const deleteUser = data => request(POST, 'users.delete', data);

export const changeUserInfo = data => request(POST, 'users.modifyInfo', data);

export const serUserPrivilege = data => request(POST, 'users.setPrivilege', data);

export const changePassword = data => request(POST, 'users.updatePasswd', data);

export const getIssueList = () => request(GET, 'posts.getAllIssues');

export const getBriefingList = () => request(GET, 'posts.patchGetInfo');

export const deleteBriefing = data => request(POST, 'posts.delete', data);

export const saveBriefing = data => request(POST, 'posts.update', data);

export const getBriefingContent = data => request(GET, 'posts/contents', data);

export const uploadImage = data => request(POST, 'images', data);

export const sendEmail = data => request(POST, 'https://upick-mp.dev.hustonline.net/api/v1/email', data);
