import React from 'react';
import PropTypes from 'prop-types';
import addBtn from 'assets/icons/add.svg';
import style from './style.scss';

const AddButton = props => (
  <section className={style.container} onClick={props.onClick}>
    <img className={style['add-btn']} src={addBtn} alt="add" />
  </section>
);

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AddButton;
