import React from 'react';
import PropTypes from 'prop-types';
import { DropDownButton } from 'components';
import moduleIcon from 'assets/icons/module.svg';
import componentIcon from 'assets/icons/component.svg';
import leftAlignIcon from 'assets/icons/left_align.svg';
import leftAlignWhiteIcon from 'assets/icons/left_align_white.svg';
import centerAlignIcon from 'assets/icons/center_align.svg';
import centerAlignWhiteIcon from 'assets/icons/center_align_white.svg';
import style from './style.scss';

const ComponentMenu = props => (
  <div className={style.container}>
    <dl className={style.block}>
      <dt className={style.title}>
        <img src={moduleIcon} alt="module" />
        模块
      </dt>
      <dd className={style.content}>
        {props.content.module.map(module => (
          <button
            key={module.description}
            className={style.button}
            onClick={module.onClick}
            type="button"
          >
            {module.description}
          </button>
        ))}
      </dd>
    </dl>
    <dl className={style.block}>
      <dt className={style.title}>
        <img src={componentIcon} alt="component" />
        排版
      </dt>
      <dd className={style.content}>
        {props.content.component.map(component => (
          <button
            key={component.description}
            className={style.button}
            onClick={component.onClick}
            type="button"
          >
            {component.description}
          </button>
        ))}
        {props.content.compositeComponent.map(compositeComponent => (
          <DropDownButton
            title={compositeComponent.description}
            subtitle={compositeComponent.subtitle}
            key={compositeComponent.subtitle}
            content={compositeComponent.content}
            imgSrc={compositeComponent.subtitle === '左对齐' ? leftAlignIcon : centerAlignIcon}
            activeImgSrc={
              compositeComponent.subtitle === '左对齐' ? leftAlignWhiteIcon : centerAlignWhiteIcon
            }
          />
        ))}
      </dd>
    </dl>
  </div>
);

ComponentMenu.propTypes = {
  content: PropTypes.shape({
    module: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
      })
    ).isRequired,
    component: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
      })
    ).isRequired,
    compositeComponent: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        content: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired
          })
        ).isRequired
      })
    ).isRequired
  }).isRequired
};

export default ComponentMenu;
