import { createAction } from '@twcn/redux-tools';
import * as types from 'constants/actionTypes';

const createActions = type => ({
  request: payload => ({ type: type.REQUEST, payload }),
  success: response => ({ type: type.SUCCESS, response }),
  failure: error => ({ type: type.FAILURE, error })
});

/* ---------- 以下为多个状态的action ---------- */

/* 账户相关 */
export const login = createActions(types.LOGIN);
export const getUserInfo = createActions(types.GET_USER_INFO);
export const changePassword = createActions(types.CHANGE_PASSWORD);

/* 用户管理 */
export const getUsersList = createActions(types.GET_USERS_LIST);
export const addUser = createActions(types.ADD_USER);
export const deleteUser = createActions(types.DELETE_USER);
export const changeUserInfo = createActions(types.CHANGE_USER_INFO);
export const setUserPrivilege = createActions(types.SET_USER_PRIVILEGE);

/* 简报操作 */
export const getIssueList = createActions(types.GET_ISSUE_LIST);
export const getBriefingList = createActions(types.GET_BRIEFING_LIST);
export const getBriefingContent = createActions(types.GET_BRIEFING_CONTENT);
export const saveBriefing = createActions(types.SAVE_BRIEFING);
export const deleteBriefing = createActions(types.DELETE_BRIEFING);

/* 编辑器界面消息提示 */
export const showMessage = createActions(types.SHOW_MESSAGE);

/* 发送邮件 */
export const sendEmail = createActions(types.SEND_EMAIL);

/* ---------- 以下为单一状态的action ---------- */

export const rememberLoginStatus = createAction(types.REMEMBER_LOGIN_STATUS);
export const logout = createAction(types.LOGOUT);

/* 确认弹窗 */
export const confirmDeleteUser = createAction(types.CONFIRM_DELETE_USER);
export const confirmChangeUser = createAction(types.CONFIRM_CHANGE_USER);
export const confirmDeleteBriefing = createAction(types.CONFIRM_DELETE_BRIEFING);
export const confirmChangePassword = createAction(types.CONFIRM_CHANGE_PASSWORD);
export const confirmAddUser = createAction(types.CONFIRM_ADD_USER);
export const editUserInfo = createAction(types.EDIT_USER_INFO);
export const showEmailBox = createAction(types.SHOW_EMAIL_BOX);

/* 编辑器相关操作 */
export const newBriefing = createAction(types.NEW_BRIEFING);
export const addElement = createAction(types.ADD_ELEMENT);
export const deleteElement = createAction(types.DELETE_ELEMENT);
export const moveElement = createAction(types.MOVE_ELEMENT);
export const editModule = createAction(types.EDIT_MODULE);
export const changeBriefingConfig = createAction(types.CHANGE_BRIEFING_CONFIG);
export const changeBriefingContent = createAction(types.CHANGE_BRIEFING_CONTENT);
export const resetBriefingContent = createAction(types.RESET_BRIEFING_CONTENT);
export const showSourceCode = createAction(types.SHOW_SOURCE_CODE);
