import React from 'react';
import PropTypes from 'prop-types';

const Img = props => (
  <img
    alt={props.alt}
    src={props.src}
    width={props.width}
    height={props.height}
    style={{
      display: 'block',
      outline: 'none',
      border: 'none',
      textDecoration: 'none',
      ...props.style
    }}
  />
);

Img.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  style: PropTypes.object // eslint-disable-line
};

export default Img;
