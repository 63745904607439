import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Header, AddButton, ConfirmModal, MembersManage, InputText
} from 'components';
import {
  getUsersList,
  editUserInfo,
  addUser,
  deleteUser,
  changeUserInfo,
  confirmDeleteUser,
  confirmAddUser,
  confirmChangeUser,
  setUserPrivilege
} from 'store/actions';

class UsersPage extends React.Component {
  static mapStateToProps = ({ usersList }) => ({
    usersList: usersList.usersList,
    deleteUserId: usersList.deleteUserId,
    editId: usersList.editId,
    editUsername: usersList.editUsername,
    editDepartment: usersList.editDepartment,
    showDeleteModal: usersList.showDeleteModal,
    showAddUserModal: usersList.showAddUserModal,
    showChangeUserModal: usersList.showChangeUserModal,
    errorMessage: usersList.errorMessage
  });

  static mapDispatchToProps = dispatch => ({
    fetUsersList: () => dispatch(getUsersList.request()),
    editUserInfo: payload => dispatch(editUserInfo(payload)),
    confirmDeleteUser: payload => dispatch(confirmDeleteUser(payload)),
    confirmAddUser: payload => dispatch(confirmAddUser(payload)),
    confirmChangeUser: payload => dispatch(confirmChangeUser(payload)),
    deleteUser: id => dispatch(deleteUser.request({ id })),
    addUser: payload => dispatch(addUser.request(payload)),
    changeUserInfo: payload => dispatch(changeUserInfo.request(payload)),
    setUserPrivilege: payload => dispatch(setUserPrivilege.request(payload))
  });

  static propTypes = {
    errorMessage: PropTypes.string.isRequired,
    deleteUserId: PropTypes.string.isRequired,
    editId: PropTypes.string.isRequired,
    editUsername: PropTypes.string.isRequired,
    editDepartment: PropTypes.string.isRequired,
    showDeleteModal: PropTypes.bool.isRequired,
    showAddUserModal: PropTypes.bool.isRequired,
    showChangeUserModal: PropTypes.bool.isRequired,
    confirmDeleteUser: PropTypes.func.isRequired,
    confirmAddUser: PropTypes.func.isRequired,
    confirmChangeUser: PropTypes.func.isRequired,
    fetUsersList: PropTypes.func.isRequired,
    editUserInfo: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    addUser: PropTypes.func.isRequired,
    changeUserInfo: PropTypes.func.isRequired,
    setUserPrivilege: PropTypes.func.isRequired,
    usersList: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string.isRequired,
        type: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        department: PropTypes.string.isRequired
      })
    ).isRequired
  };

  componentDidMount() {
    this.props.fetUsersList();
  }

  handleChange = (e) => {
    this.props.editUserInfo({
      [e.target.name]: e.target.value.trim()
    });
  };

  render() {
    return (
      <>
        <Header link="/users" title="成员管理" />
        <MembersManage
          clickDeleteUserButton={this.props.confirmDeleteUser}
          clickEditUserButton={this.props.confirmChangeUser}
          setUserPrivilege={this.props.setUserPrivilege}
          usersList={this.props.usersList}
        />
        <ConfirmModal
          dangerous
          show={this.props.showDeleteModal}
          title="删除成员"
          text={`确定要删除用户 ${this.props.deleteUserId} 吗？`}
          confirmMsg="确认删除"
          confirm={() => this.props.deleteUser(this.props.deleteUserId)}
          cancel={() => this.props.confirmDeleteUser({
            showDeleteModal: false
          })
          }
        />
        <ConfirmModal
          show={this.props.showAddUserModal}
          title="新建成员"
          confirmMsg="添加"
          tip="新成员密码默认为学号"
          warning={this.props.errorMessage}
          confirm={() => this.props.addUser({
            id: this.props.editId,
            username: this.props.editUsername,
            department: this.props.editDepartment
          })
          }
          cancel={() => this.props.confirmAddUser({
            showAddUserModal: false
          })
          }
        >
          <InputText
            value={this.props.editId}
            placeholder="学号"
            name="editId"
            onChange={this.handleChange}
          />
          <InputText
            value={this.props.editUsername}
            placeholder="姓名"
            name="editUsername"
            onChange={this.handleChange}
          />
          <InputText
            value={this.props.editDepartment}
            placeholder="院系"
            name="editDepartment"
            onChange={this.handleChange}
          />
        </ConfirmModal>
        <ConfirmModal
          show={this.props.showChangeUserModal}
          title="编辑成员"
          confirmMsg="修改"
          tip="只能修改用户姓名和院系，如需修改学号请新建账户"
          warning={this.props.errorMessage}
          confirm={() => this.props.changeUserInfo({
            id: this.props.editId,
            username: this.props.editUsername,
            department: this.props.editDepartment
          })
          }
          cancel={() => this.props.confirmChangeUser({
            showChangeUserModal: false
          })
          }
        >
          <InputText
            disabled
            value={this.props.editId}
            placeholder="学号"
            name="editId"
            onChange={this.handleChange}
          />
          <InputText
            value={this.props.editUsername}
            placeholder="姓名"
            name="editUsername"
            onChange={this.handleChange}
          />
          <InputText
            value={this.props.editDepartment}
            placeholder="院系"
            name="editDepartment"
            onChange={this.handleChange}
          />
        </ConfirmModal>
        <AddButton
          onClick={() => this.props.confirmAddUser({
            showAddUserModal: true
          })
          }
        />
      </>
    );
  }
}

export default connect(
  UsersPage.mapStateToProps,
  UsersPage.mapDispatchToProps
)(UsersPage);
