import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  HomePage,
  LoginPage,
  UsersPage,
  ProfilePage,
  EditorPage,
  NewsPage
} from 'containers';
import { getLocal, removeLocal, setLocal } from '../utils/cache';

const routes = [
  {
    path: '/',
    component: HomePage,
    exact: true
  },
  {
    path: '/login',
    component: LoginPage
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/users',
    component: UsersPage
  },
  {
    path: '/edit',
    component: EditorPage
  },
  {
    path: '/news/:totalIssue',
    component: NewsPage
  }
];

const RouteWithSubRoutes = route => (
  <Route
    path={route.path}
    render={props => <route.component {...props} routes={route.routes} />}
  />
);

// const RootRoute = () => (
//   <Switch>
//     {routes.map((route, i) => (
//       <RouteWithSubRoutes key={i} {...route} /> // eslint-disable-line
//     ))}
//   </Switch>
// );

class RootRoute extends React.Component {
  deleteToken = () => {
    setLocal('tabs', getLocal('tabs') - 1);
    if (!getLocal('rememberStatus') && getLocal('tabs') <= 0) {
      removeLocal('token');
    }
    if (getLocal('tabs') <= 0) {
      removeLocal('tabs');
    }
  };
  componentDidMount() {
    const curTabs = getLocal('tabs') === null ? 0 : getLocal('tabs');
    setLocal('tabs', curTabs + 1);
    window.addEventListener('beforeunload', this.deleteToken);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.deleteToken);
  }

  render() {
    return (
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} /> // eslint-disable-line
        ))}
      </Switch>
    );
  }
}

export default RootRoute;
