import React from 'react';
import PropTypes from 'prop-types';
import { ComboBox } from 'components';
import userIcon from 'assets/icons/user.svg';
import downArrowIcon from 'assets/icons/down_arrow.svg';
import style from './style.scss';

const User = props => (
  <div className={`${style.container} ${props.className}`} onClick={props.onClick}>
    <div className={style['user-icon']}>
      <img src={userIcon} alt="user" />
    </div>
    <div className={style.username}>{props.username}</div>
    <img className={style['down-arrow']} src={downArrowIcon} alt="down_arrow" />
    <ComboBox
      className={props.showComboBox ? style['combo-box'] : style.hide}
      type={props.type}
      logout={props.logout}
    />
  </div>
);

User.defaultProps = {
  className: ''
};

User.propTypes = {
  className: PropTypes.string,
  showComboBox: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default User;
