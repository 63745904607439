import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Header, Footer } from 'editor';
import { moveElement } from 'store/actions';
import style from './style.scss';
import renderElement from '../../render';

class EditArea extends React.Component {
  static mapDispatchToProps = dispatch => ({
    moveElement: (parentIndex, index, destination) => dispatch(
      moveElement({ parentIndex, index, destination })
    )
  });

  static propTypes = {
    config: PropTypes.shape({
      date: PropTypes.string.isRequired,
      issue: PropTypes.number.isRequired,
      totalIssue: PropTypes.number.isRequired
    }).isRequired,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    moveElement: PropTypes.func.isRequired
  };

  onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    this.props.moveElement(-1, source.index, destination.index);
  };

  render() {
    const { config, content } = this.props;

    return (
      <div className={style.container} id="edit-area">
        <article className={style['edit-area']}>
          <Header
            date={config.date}
            issue={config.issue}
            totalIssue={config.totalIssue}
          />
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="context">
              {provided => (
                <section
                  className={style.content}
                  ref={provided.innerRef}
                >
                  {content.map((item, index) => (
                    <Draggable
                      key={item.name + index} // eslint-disable-line
                      draggableId={item.name + index}
                      index={index}
                    >
                      {(provided) => (  // eslint-disable-line
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {renderElement('editor', item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                </section>
              )}
            </Droppable>
          </DragDropContext>
          <Footer />
        </article>
      </div>
    );
  }
}

export default connect(
  null,
  EditArea.mapDispatchToProps
)(EditArea);
