import React from 'react';
import { Box } from 'react-html-email';

const Module = ({ style, ...props }) => (
  <Box
    style={{
      width: '100%',
      margin: '30px 0 40px', // 模块间的间距
      ...style
    }}
    {...props}
  />
);

export default Module;
