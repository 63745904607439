import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';

const SaveButton = props => (
  <button type="button" className={style.button} onClick={props.onClick}>
    保存
  </button>
);

SaveButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default SaveButton;
