import React from 'react';
import PropTypes from 'prop-types';
import bookIcon from 'assets/icons/book.svg';
import clockIcon from 'assets/icons/clock.svg';
import style from './style.scss';

const SettingMenu = props => (
  <dl className={style.container}>
    <dt className={style.title}>
      <img src={bookIcon} alt="icon" />
      期数
    </dt>
    <dd className={style.content}>{props.issueComponent}</dd>
    <dt className={style.title}>总期数</dt>
    <dd className={style.content}>{props.totalIssueComponent}</dd>
    <dt className={style.title}>
      <img src={clockIcon} alt="icon" />
      时间
    </dt>
    <dd className={style.content}>{props.dateComponent}</dd>
  </dl>
);

SettingMenu.propTypes = {
  issueComponent: PropTypes.node.isRequired,
  totalIssueComponent: PropTypes.node.isRequired,
  dateComponent: PropTypes.node.isRequired
};

export default SettingMenu;
