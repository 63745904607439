import * as types from 'constants/actionTypes';
import objectEvery from 'utils/objectEvery';

/* redux中间件拦截器 */
const interceptor = store => next => action => {
  // 添加组件时页面自动下滚
  if (action.type === types.ADD_ELEMENT) {
    const { briefing } = store.getState();
    const parentIndex =
      action.payload.type !== 'component' ? -1 : briefing.activeModuleIndex;

    const index =
      parentIndex > -1 && action.payload.type === 'component' // 添加模块内的组件时
        ? briefing.content[parentIndex] &&
          briefing.content[parentIndex].children &&
          briefing.content[parentIndex].children.length - 1
        : briefing.content.length - 1;
    setTimeout(() => {
      const element = document.getElementById(
        `element${parentIndex}-${index + 1}`
      );
      if (element) {
        const offsetTop =
          parentIndex > -1 && action.payload.type === 'component'
            ? element.offsetParent.offsetTop + element.offsetTop - 100
            : element.offsetTop;
        document.getElementById('edit-area').scrollTop = offsetTop;
      }
    }, 0);
  }

  // 留空提示
  if (action.type === types.SAVE_BRIEFING.REQUEST) {
    const { briefing } = store.getState();
    let parentIndex = -1;
    let index = -1;
    const isComplete = briefing.content.every((item, contentIndex) => {
      if (item.type === 'component') {
        parentIndex = -1;
        index = contentIndex;
        return objectEvery(item, value => !!value);
      }
      if (item.type === 'compositeComponent') {
        parentIndex = -1;
        index = contentIndex;
        if (item.children.length <= 1) {
          return false;
        }
        if (item.name.includes('Three') && item.children.length < 3) {
          return false;
        }
        // 目前的复合组件里都有三项且都为必填
        return item.children.every(
          child => child.title && child.text && child.imgSrc
        );
      }
      parentIndex = contentIndex;
      return item.children.every((child, childIndex) => {
        index = childIndex;
        return objectEvery(child, value => !!value);
      });
    });
    if (!isComplete) {
      const element = document.getElementById(`element${parentIndex}-${index}`);
      const offsetTop =
        parentIndex > -1 && action.payload.type !== 'module'
          ? element.offsetParent.offsetTop + element.offsetTop
          : element.offsetTop;
      document.getElementById('edit-area').scrollTop = offsetTop - 100;

      next({
        type: types.SHOW_MESSAGE.REQUEST,
        payload: {
          status: 'failure',
          messageText: '内容未填写完!'
        }
      });

      setTimeout(() => {
        next({
          type: types.SHOW_MESSAGE.SUCCESS
        });
      }, 3000);
      return;
    }
  }

  next(action);

  /* // 原始版本
  if (action.type === types.ADD_ELEMENT && store.getState().briefing.activeModuleIndex === -1) {
    setTimeout(() => {
      document.getElementById('edit-area').scrollTop =
        document.getElementById('edit-area').scrollHeight;
    }, 0);
  } */
};

export default interceptor;
