import {
  call, put, takeLatest, takeEvery, all
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import delay from 'utils/delay';
import objectEvery from 'utils/objectEvery';
import * as types from 'constants/actionTypes';
import * as actions from '../actions';
import * as api from '../../api';

function* login(action) {
  try {
    if (!action.payload.id || !action.payload.password) {
      yield put(actions.login.failure({ message: '账户或密码不能为空！' }));
      return;
    }
    const res = yield call(api.login, action.payload);
    if (res.status === 200 && res.data.success) {
      yield put(actions.login.success(res.data.data));
      yield put(push('/'));
    } else {
      yield put(actions.login.failure({ message: '您输入的帐号或者密码不正确，请重新输入。' }));
    }
  } catch (error) {
    yield put(actions.login.failure(error));
  }
}

function* getUserInfo() {
  try {
    const res = yield call(api.getUserInfo);
    if (res.status === 200 && res.data.success) {
      yield put(actions.getUserInfo.success(res.data.data));
    } else {
      yield put(actions.getUserInfo.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.getUserInfo.failure(error));
  }
}

function* changePassword(action) {
  try {
    if (!action.payload.password || !action.payload.reEnterPassword) {
      yield put(actions.changePassword.failure({ message: '密码不能为空' }));
      return;
    }
    if (action.payload.password !== action.payload.reEnterPassword) {
      yield put(actions.changePassword.failure({ message: '两次输入的密码不一致' }));
      return;
    }
    const res = yield call(api.changePassword, { password: action.payload.password });
    if (res.status === 200 && res.data.success) {
      yield put(actions.changePassword.success());
    } else {
      yield put(actions.changePassword.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.changePassword.failure(error));
  }
}

function* getUsersList() {
  try {
    const res = yield call(api.getUsersList);
    if (res.status === 200 && res.data.success) {
      yield put(actions.getUsersList.success(res.data.data));
    } else {
      yield put(actions.getUsersList.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.getUsersList.failure(error));
  }
}

function* addUser(action) {
  try {
    const isValid = objectEvery(action.payload, item => item !== '');
    if (!isValid) {
      yield put(actions.addUser.failure({ message: '输入参数不能为空' }));
      return;
    }
    const res = yield call(api.addUser, action.payload);
    if (res.status === 200 && res.data.success) {
      yield put(actions.addUser.success());
      yield put(actions.getUsersList.request());
    } else {
      yield put(actions.addUser.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.addUser.failure(error));
  }
}

function* deleteUser(action) {
  try {
    const res = yield call(api.deleteUser, action.payload);
    if (res.status === 200 && res.data.success) {
      yield put(actions.deleteUser.success());
      yield put(actions.getUsersList.request());
    } else {
      yield put(actions.deleteUser.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.deleteUser.failure(error));
  }
}

function* changeUserInfo(action) {
  try {
    const isValid = objectEvery(action.payload, item => item !== '');
    if (!isValid) {
      yield put(actions.addUser.failure({ message: '输入参数不能为空' }));
      return;
    }
    const res = yield call(api.changeUserInfo, action.payload);
    if (res.status === 200 && res.data.success) {
      yield put(actions.changeUserInfo.success());
      yield put(actions.getUsersList.request());
    } else {
      yield put(actions.changeUserInfo.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.changeUserInfo.failure(error));
  }
}

function* setUserPrivilege(action) {
  try {
    const res = yield call(api.serUserPrivilege, action.payload);
    if (res.status === 200 && res.data.success) {
      yield put(actions.setUserPrivilege.success());
      yield put(actions.getUsersList.request());
    } else {
      yield put(actions.setUserPrivilege.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.setUserPrivilege.failure(error));
  }
}

function* getIssueList() {
  try {
    const res = yield call(api.getIssueList);
    if (res.status === 200 && res.data.success) {
      yield put(actions.getIssueList.success(res.data.data));
    } else {
      yield put(actions.getIssueList.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.getIssueList.failure(error));
  }
}

function* getBriefingList() {
  try {
    const res = yield call(api.getBriefingList);
    if (res.status === 200 && res.data.success) {
      yield put(actions.getBriefingList.success(res.data.data));
    } else {
      yield put(actions.getBriefingList.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.getBriefingList.failure(error));
  }
}

function* deleteBriefing(action) {
  try {
    const res = yield call(api.deleteBriefing, action.payload);
    if (res.status === 200 && res.data.success) {
      yield put(actions.deleteBriefing.success());
      yield put(actions.getBriefingList.request());
    } else {
      yield put(actions.deleteBriefing.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.deleteBriefing.failure(error));
  }
}

function* saveBriefing(action) {
  try {
    const res = yield call(api.saveBriefing, action.payload);
    if (res.status === 200 && res.data.success) {
      yield put(actions.saveBriefing.success());
      yield put(
        actions.showMessage.request({
          status: 'success',
          messageText: '保存简报成功'
        })
      );
      window.localStorage.setItem('saveBriefing', action.payload.totalIssue); // 这里是为了跨页面通信，保存后通知首页刷新简报列表
    } else {
      yield put(actions.saveBriefing.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.saveBriefing.failure(error));
    yield put(
      actions.showMessage.request({
        status: 'failure',
        messageText: `保存简报失败： ${error.message}`
      })
    );
  }
}

function* getBriefingContent(action) {
  try {
    const res = yield call(api.getBriefingContent, action.payload);
    if (res.status === 200 && res.data.success) {
      yield put(actions.getBriefingContent.success(res.data.data));
    } else {
      yield put(actions.getBriefingContent.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.getBriefingContent.failure(error));
  }
}

function* showMessage() {
  yield delay(3000);
  yield put(actions.showMessage.success());
}

function* sendEmail(action) {
  try {
    if (action.payload.emailTos.length === 0) {
      yield put(actions.sendEmail.failure({ message: '收件人列表不能为空' }));
      return;
    }
    if (!action.payload.emailTos.every(email => /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email))) {
      yield put(actions.sendEmail.failure({ message: '邮箱地址不合法' }));
      return;
    }
    const res = yield call(api.sendEmail, action.payload);
    if (res.status === 200 && res.data.status === 200) {
      yield put(actions.sendEmail.success(res.data.data));
      yield put(
        actions.showMessage.request({
          status: 'success',
          messageText: '发送邮件成功'
        })
      );
    } else {
      yield put(actions.sendEmail.failure({ message: res.data.error }));
    }
  } catch (error) {
    yield put(actions.sendEmail.failure(error));
    yield put(
      actions.showMessage.request({
        status: 'failure',
        messageText: `发送邮件失败： ${error.message}`
      })
    );
  }
}

function* rootSaga() {
  yield all([
    takeLatest(types.LOGIN.REQUEST, login),
    takeLatest(types.GET_USER_INFO.REQUEST, getUserInfo),
    takeLatest(types.GET_USERS_LIST.REQUEST, getUsersList),
    takeEvery(types.ADD_USER.REQUEST, addUser),
    takeEvery(types.DELETE_USER.REQUEST, deleteUser),
    takeEvery(types.CHANGE_USER_INFO.REQUEST, changeUserInfo),
    takeEvery(types.SET_USER_PRIVILEGE.REQUEST, setUserPrivilege),
    takeLatest(types.CHANGE_PASSWORD.REQUEST, changePassword),
    takeLatest(types.GET_ISSUE_LIST.REQUEST, getIssueList),
    takeLatest(types.GET_BRIEFING_LIST.REQUEST, getBriefingList),
    takeLatest(types.GET_BRIEFING_CONTENT.REQUEST, getBriefingContent),
    takeEvery(types.DELETE_BRIEFING.REQUEST, deleteBriefing),
    takeEvery(types.SAVE_BRIEFING.REQUEST, saveBriefing),
    takeLatest(types.SHOW_MESSAGE.REQUEST, showMessage),
    takeLatest(types.SEND_EMAIL.REQUEST, sendEmail)
  ]);
}

export default rootSaga;
