import produce from 'immer';
import moment from 'moment';
import * as types from 'constants/actionTypes';
import { getLocal } from 'utils/cache';
import moveArrayItem from 'utils/moveArrayItem';

const initialState = getLocal('briefing') || {
  config: {
    issue: 0,
    totalIssue: 0,
    date: moment().format('YYYY-MM-DD')
  },
  content: [],
  isEditingModule: false,
  activeModuleIndex: -1
};

const briefing = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BRIEFING_CONTENT.REQUEST:
      return state;
    case types.GET_BRIEFING_CONTENT.SUCCESS:
      {
        const content = JSON.parse(action.response.content);
        return {
          ...state,
          content: content.content,
          config: content.config
        };
      }
    case types.GET_BRIEFING_CONTENT.FAILURE:
      return {
        ...state,
        content: []
      };

    case types.CHANGE_BRIEFING_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.payload
        }
      };

    case types.CHANGE_BRIEFING_CONTENT:
      {
        const { parentIndex, index, content } = action.payload;
        /* eslint-disable no-param-reassign */
        const newContent = produce(state.content, (draftContent) => {
          if (parentIndex < 0) {
            draftContent[index] = {
              ...draftContent[index],
              ...content
            };
          } else {
            draftContent[parentIndex].children[index] = {
              ...draftContent[parentIndex].children[index],
              ...content
            };
          }
        });
        return {
          ...state,
          content: newContent
        };
      }

    case types.RESET_BRIEFING_CONTENT:
      return {
        ...state,
        content: []
      };

    case types.ADD_ELEMENT:
      {
        if (
          action.payload.type === 'component' &&
          state.isEditingModule &&
          state.activeModuleIndex >= 0
        ) {
          const newContent = produce(state.content, (draftContent) => {
            draftContent[state.activeModuleIndex].children.push(action.payload);
          });
          return {
            ...state,
            content: newContent
          };
        }
        if (action.payload.type === 'module') {
          return {
            ...state,
            content: state.content.concat({
              ...action.payload,
              children: []
            })
          };
        }
        return {
          ...state,
          content: state.content.concat(action.payload)
        };
      }

    case types.DELETE_ELEMENT:
      {
        const { parentIndex, index } = action.payload;
        if (parentIndex < 0) {
          return {
            ...state,
            content: state.content.filter((_, i) => i !== index)
          };
        }
        const newContent = produce(state.content, (draftContent) => {
          draftContent[parentIndex].children.splice(index, 1);
        });
        return {
          ...state,
          content: newContent
        };
      }

    case types.MOVE_ELEMENT:
      {
        const { destination, index, parentIndex } = action.payload;
        const newContent = state.content.slice();

        // 第一层移动
        if (parentIndex < 0) {
          // 移动正在编辑的模块
          let { activeModuleIndex, content } = state;
          if (activeModuleIndex !== -1) {
            if (index === activeModuleIndex) {
              activeModuleIndex = destination;
            } else if (destination > index) {
              activeModuleIndex -= 1;
            } else {
              activeModuleIndex += 1;
            }
          }
          return {
            ...state,
            content: moveArrayItem(state.content, index, destination),
            activeModuleIndex
          };
        }

        // 模块内移动
        const module = state.content[parentIndex];
        newContent.splice(parentIndex, 1, {
          ...module,
          children: moveArrayItem(module.children, index, destination)
        });

        return {
          ...state,
          content: newContent
        };
      }

    case types.EDIT_MODULE:
      {
        if (action.payload.state) {
          // 编辑模块
          return {
            ...state,
            isEditingModule: true,
            activeModuleIndex: action.payload.index
          };
        }
        return {
          // 完成模块编辑
          ...state,
          isEditingModule: false,
          activeModuleIndex: -1
        };
      }

    default:
      return state;
  }
};

export default briefing;
