import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeBriefingContent } from 'store/actions';
import mapFontSize from 'utils/mapFontSize';
import style from './style.scss';
import Component from '../Component';
import FontSize from '../FontSize';

class TopicLink extends React.Component {
  static mapDispatchToProps = dispatch => ({
    changeBriefingContent: payload => dispatch(changeBriefingContent(payload))
  });

  static propTypes = {
    index: PropTypes.number.isRequired,
    parentIndex: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    titleFontSize: PropTypes.string.isRequired,
    changeBriefingContent: PropTypes.func.isRequired
  };

  handleChange = (e) => {
    this.props.changeBriefingContent({
      parentIndex: this.props.parentIndex,
      index: this.props.index,
      content: {
        [e.target.name]: e.target.value
      }
    });
  };

  render() {
    return (
      <Component {...this.props}>
        <div className={style.container}>
          <div className={style.block} />
          <FontSize {...this.props} className={style['title-wrap']} fontSize={this.props.titleFontSize}>
            <input
              type="text"
              name="title"
              className={style.input}
              value={this.props.title}
              onChange={this.handleChange}
              placeholder="请输入标题"
              style={{ fontSize: mapFontSize(this.props.titleFontSize) }}
            />
          </FontSize>
          <button className={style.button} type="button">了解更多</button>
        </div>
      </Component>
    );
  }
}

export default connect(
  null,
  TopicLink.mapDispatchToProps
)(TopicLink);
