import React from 'react';
import PropTypes from 'prop-types';
import ReactCropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { uploadImage } from '../../api';
import style from './style.scss';

class Cropper extends React.Component {
  static defaultProps = {
    aspectRatio: null
  };

  static propTypes = {
    id: PropTypes.string.isRequired,
    aspectRatio: PropTypes.number,
    confirm: PropTypes.func.isRequired
  };

  state = {
    cropImgUrl: '',
    showFileInput: true,
    showCropModal: false
  };

  cropperRef = React.createRef();

  fileRef = React.createRef();

  onFileChange = () => {
    const file = this.fileRef.current.files[0];
    const imageType = /^image\//;
    if (!file || !imageType.test(file.type)) return;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.setState({
        showFileInput: false, // 修复input选择同一个文件onchange事件不触发的问题
        showCropModal: true,
        cropImgUrl: fileReader.result
      });
    };
    fileReader.readAsDataURL(file);
  };

  confirmCrop = async () => {
    this.setState({
      showFileInput: true,
      showCropModal: false
    });
    const imgDataUrl = this.cropperRef.current.getCroppedCanvas().toDataURL();
    // const noPrefixImgDataUrl = imgDataUrl.replace(/^data:image\/(\w+);base64,/, '');
    try {
      const res = await uploadImage({ image: imgDataUrl });
      const imgUrl = res.data.data.url;
      this.props.confirm(imgUrl);
    } catch (e) {
      console.error('Image Upload Error! ', e); // eslint-disable-line
    }
  };

  render() {
    return (
      <>
        {this.state.showCropModal && (
          <div className={style.crop}>
            <div className={style['crop-area']}>
              <ReactCropper
                {...this.props}
                src={this.state.cropImgUrl}
                ref={this.cropperRef}
                style={{ height: '100%', width: '100%' }}
                guides={false}
              />
            </div>
            <div className={style.buttons}>
              <button
                type="button"
                className={style.cancel}
                onClick={() => this.setState({ showCropModal: false })}
              >
                取消
              </button>
              <button
                type="button"
                className={style.confirm}
                onClick={this.confirmCrop}
              >
                确定
              </button>
            </div>
          </div>
        )}
        {this.state.showFileInput && (
          <input
            ref={this.fileRef}
            className={style.fileInput}
            type="file"
            accept="image/*"
            id={this.props.id}
            onChange={this.onFileChange}
          />
        )}
      </>
    );
  }
}

export default Cropper;
