import * as types from 'constants/actionTypes';

const initialState = {
  isShowMessage: false,
  isShowCode: false,
  isShowEmailBox: false,
  status: '',
  messageText: '',
  sourceCode: '',
  type: 'code',
  isNew: false,
  errorMessage: ''
};

const editor = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MESSAGE.REQUEST:
      return {
        ...state,
        isShowMessage: true,
        ...action.payload
      };
    case types.SHOW_MESSAGE.SUCCESS:
      return {
        ...state,
        isShowMessage: false
      };
    case types.SHOW_SOURCE_CODE:
      return {
        ...state,
        isShowCode: action.payload.show,
        sourceCode: action.payload.sourceCode || state.sourceCode,
        type: action.payload.type || state.type
      };
    case types.NEW_BRIEFING:
      return {
        ...state,
        isNew: true
      };
    case types.SHOW_EMAIL_BOX:
      return {
        ...state,
        isShowEmailBox: action.payload
      };
    case types.SEND_EMAIL.REQUEST:
      return {
        ...state,
        errorMessage: ''
      };
    case types.SEND_EMAIL.SUCCESS:
      return {
        ...state,
        isShowEmailBox: false
      };
    case types.SEND_EMAIL.FAILURE:
      return {
        ...state,
        errorMessage: action.error.message
      };
    default:
      return state;
  }
};

export default editor;
