import React from 'react';
import PropTypes from 'prop-types';
import { Item } from 'react-html-email';
import { Module, Span } from './base';

const GrayBackground = props => (
  <Module
    style={{
      backgroundColor: '#f2f2f2'
    }}
  >
    <Item
      style={{
        padding: '15px 0',
        textAlign: 'center',
        borderBottom: '1px solid #ccc'
      }}
    >
      <Span
        style={{
          padding: '3px 20px',
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#323b6f',
          textAlign: 'center',
          borderBottom: '1px solid #ccc'
        }}
      >
        {props.title}
      </Span>
    </Item>
    <Item>{props.children}</Item>
  </Module>
);

GrayBackground.defaultProps = {
  children: null
};

GrayBackground.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default GrayBackground;
