import React from 'react';
import PropTypes from 'prop-types';
import { Item } from 'react-html-email';
import mapFontSize from 'utils/mapFontSize';
import { Component, P, A, Button } from './base';

const Banner = props => (
  <Component
    style={{
      background: `#323b6f url(${props.imgSrc}) no-repeat center center`,
      backgroundSize: '100% 100%'
    }}
  >
    <Item
      align="center"
      style={{
        padding: '80px 36px 32px'
      }}
    >
      {props.title.split('\n').map(sentence => (
        <P
          style={{
            color: '#fff',
            fontSize: mapFontSize(props.titleFontSize) || '36px',
            fontWeight: 'bold'
          }}
        >
          {sentence}
        </P>
      ))}
    </Item>
    <Item
      align="left"
      style={{
        padding: '0 36px'
      }}
    >
      {props.text.split('\n').map(sentence => (
        <P
          style={{
            fontSize: mapFontSize(props.textFontSize) || '18px',
            color: '#fff'
          }}
        >
          {sentence}
        </P>
      ))}
    </Item>
    <Item
      align="right"
      style={{
        padding: '20px 24px 22px 0',
        height: '24px'
      }}
    >
      <A href={props.link}>
        <Button
          style={{
            padding: '6px 16px',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#fff',
            backgroundColor: '#5c75ff'
          }}
        >
          了解更多
        </Button>
      </A>
    </Item>
  </Component>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  titleFontSize: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textFontSize: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default Banner;
