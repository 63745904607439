import React from 'react';

const Span = ({ style, children, ...props }) => (
  <span
    style={{
      fontFamily: 'Microsoft YaHei, PingFang SC, Noto Sans CJK, SimHei',
      ...style
    }}
    {...props}
  >
    {children}
  </span>
);

export default Span;
