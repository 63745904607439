import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';

const InputText = props => (
  <input {...props} className={`${style.input} ${props.className}`} type="text" />
);

InputText.defaultProps = {
  className: ''
};

InputText.propTypes = {
  className: PropTypes.string
};

export default InputText;
