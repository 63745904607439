import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import moment from 'moment';
import {
  EditorHeader,
  EditorAside,
  EditArea,
  ConfirmModal,
  SaveButton,
  Message,
  SourceCodeBox,
  InputText
} from 'components';
import { ComponentPanel, SettingPanel } from 'containers';
import {
  addElement,
  resetBriefingContent,
  saveBriefing,
  changeBriefingConfig,
  getBriefingContent,
  showSourceCode,
  showMessage,
  newBriefing,
  sendEmail,
  showEmailBox
} from 'store/actions';
import renderHtml from '../renderMail';

class EditorPage extends React.Component {
  static mapStateToProps = ({ briefing, editor }) => ({
    briefing,
    isShowMessage: editor.isShowMessage,
    status: editor.status,
    messageText: editor.messageText,
    isShowCode: editor.isShowCode,
    isShowEmailBox: editor.isShowEmailBox,
    sourceCode: editor.sourceCode,
    type: editor.type,
    errorMessage: editor.errorMessage
  });

  static mapDispatchToProps = dispatch => ({
    addElement: payload => dispatch(addElement(payload)),
    resetBriefingContent: () => dispatch(resetBriefingContent()),
    getBriefingContent: payload => dispatch(getBriefingContent.request(payload)),
    saveBriefing: payload => dispatch(saveBriefing.request(payload)),
    changeBriefingConfig: payload => dispatch(changeBriefingConfig(payload)),
    showSourceCode: payload => dispatch(showSourceCode(payload)),
    showMessage: payload => dispatch(showMessage.request(payload)),
    newBriefing: () => dispatch(newBriefing()),
    sendEmail: payload => dispatch(sendEmail.request(payload)),
    showEmailBox: payload => dispatch(showEmailBox(payload))
  });

  static propTypes = {
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    briefing: PropTypes.shape({
      config: PropTypes.shape({
        issue: PropTypes.number.isRequired,
        totalIssue: PropTypes.number.isRequired
      }).isRequired,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ).isRequired
    }).isRequired,
    addElement: PropTypes.func.isRequired,
    resetBriefingContent: PropTypes.func.isRequired,
    getBriefingContent: PropTypes.func.isRequired,
    saveBriefing: PropTypes.func.isRequired,
    changeBriefingConfig: PropTypes.func.isRequired,
    sendEmail: PropTypes.func.isRequired,
    showEmailBox: PropTypes.func.isRequired,
    isShowMessage: PropTypes.bool.isRequired,
    isShowCode: PropTypes.bool.isRequired,
    isShowEmailBox: PropTypes.bool.isRequired,
    showSourceCode: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    newBriefing: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    messageText: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    sourceCode: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['code', 'preview']).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string
    }).isRequired
  };

  state = {
    showResetModal: false,
    fromName: '简报系统',
    subject: '简报',
    emailTos: ''
  };

  componentDidMount() {
    const config = qs.parse(this.props.location.search);
    const issue = parseInt(config.issue, 10);
    const totalIssue = parseInt(config.total, 10);
    if (issue < 1 || totalIssue < 1) {
      this.props.history.push('/');
    }
    this.props.changeBriefingConfig({
      totalIssue,
      issue: issue || totalIssue, // 没有issue时默认等于totalIssue
      date: moment().format('YYYY-MM-DD')
    });
    this.setState(() => ({
      subject: `简报第${totalIssue}期`
    }));
    // 当地址栏有issue（期数）时，说明是新建
    if (issue >= 1) {
      // 这个action的目的是为了判断是否应该保存内容变动到本地
      this.props.newBriefing();
    } else {
      this.props.getBriefingContent({
        totalIssue
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trim()
    });
  };

  render() {
    const { state } = this;

    return (
      <>
        <Message
          showMessage={this.props.isShowMessage}
          status={this.props.status}
          messageText={this.props.messageText}
        />
        <EditorHeader
          clickExternalLinkButton={() => window.open(`#/news/${this.props.briefing.config.totalIssue}`)}
          clickResetButton={() => this.setState({
            showResetModal: true
          })
          }
          clickCopyButton={() => this.props.showSourceCode({
            show: true,
            type: 'code',
            sourceCode: renderHtml(this.props.briefing)
          })
          }
          clickPreviewButton={() => this.props.showSourceCode({
            show: true,
            type: 'preview',
            sourceCode: renderHtml(this.props.briefing)
          })
          }
          showEmailBox={() => this.props.showEmailBox(true)}
        />
        <ConfirmModal
          dangerous
          show={state.showResetModal}
          title="简报重置"
          text="简报的所有内容将被重置"
          cancel={() => this.setState({
            showResetModal: false
          })
          }
          confirm={() => {
            this.setState({
              showResetModal: false
            });
            this.props.resetBriefingContent();
          }}
          confirmMsg="确认重置"
        />
        <EditorAside
          addElement={this.props.addElement}
          content={[
            { title: '设置', children: <SettingPanel /> },
            { title: '组件', children: <ComponentPanel /> }
          ]}
        />
        <EditArea config={this.props.briefing.config} content={this.props.briefing.content} />
        <SaveButton
          onClick={() => this.props.saveBriefing({
            content: JSON.stringify(this.props.briefing),
            issue: this.props.briefing.config.issue,
            totalIssue: this.props.briefing.config.totalIssue
          })
          }
        />
        <SourceCodeBox
          show={this.props.isShowCode}
          code={this.props.sourceCode}
          type={this.props.type}
          showMessage={this.props.showMessage}
          close={() => this.props.showSourceCode({
            show: false
          })
          }
        />
        <ConfirmModal
          show={this.props.isShowEmailBox}
          title="发送测试邮件"
          confirmMsg="发送"
          tip="多个邮箱地址请用英文逗号隔开"
          warning={this.props.errorMessage}
          confirm={() => this.props.sendEmail({
            fromName: state.fromName,
            subject: state.subject,
            content: renderHtml(this.props.briefing),
            emailTos: state.emailTos ? state.emailTos.split(',') : []
          })
          }
          cancel={() => this.props.showEmailBox(false)}
        >
          <InputText
            value={state.subject}
            placeholder="邮件主题"
            name="subject"
            onChange={this.handleChange}
          />
          <InputText
            value={state.emailTos}
            placeholder="收件人列表"
            name="emailTos"
            onChange={this.handleChange}
          />
        </ConfirmModal>
      </>
    );
  }
}

export default connect(
  EditorPage.mapStateToProps,
  EditorPage.mapDispatchToProps
)(EditorPage);
