import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import { changeBriefingContent } from 'store/actions';
import mapFontSize from 'utils/mapFontSize';
import Component from '../Component';
import FontSize from '../FontSize';
import style from './style.scss';
import Cropper from '../Cropper';

class LeftTextRightPicture extends React.Component {
  static mapDispatchToProps = dispatch => ({
    changeBriefingContent: payload => dispatch(changeBriefingContent(payload))
  });

  static propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    parentIndex: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    titleFontSize: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textFontSize: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    changeBriefingContent: PropTypes.func.isRequired
  };

  handleChange = (e) => {
    this.props.changeBriefingContent({
      parentIndex: this.props.parentIndex,
      index: this.props.index,
      content: {
        [e.target.name]: e.target.value
      }
    });
  };

  render() {
    return (
      <Component {...this.props}>
        <div className={style.container}>
          <div className={style.left}>
            <FontSize {...this.props} className={style['title-wrap']} fontSize={this.props.titleFontSize}>
              <Textarea
                name="title"
                value={this.props.title}
                onChange={this.handleChange}
                className={style.title}
                placeholder="请输入标题"
                style={{ fontSize: mapFontSize(this.props.titleFontSize) }}
              />
            </FontSize>
            <FontSize {...this.props} className={style['text-wrap']} fontSize={this.props.textFontSize}>
              <Textarea
                name="text"
                value={this.props.text}
                onChange={this.handleChange}
                className={style.text}
                placeholder="请输入描述文本"
                style={{ fontSize: mapFontSize(this.props.textFontSize) }}
              />
            </FontSize>
          </div>
          <label
            htmlFor={
              this.props.name + this.props.parentIndex + this.props.index
            }
            className={style.label}
          >
            {this.props.imgSrc && (
              <img
                className={style.cover}
                src={this.props.imgSrc}
                alt="cover"
              />
            )}
          </label>
          <Cropper
            id={this.props.name + this.props.parentIndex + this.props.index}
            aspectRatio={10 / 7}
            confirm={imgSrc => this.props.changeBriefingContent({
              parentIndex: this.props.parentIndex,
              index: this.props.index,
              content: { imgSrc }
            })
            }
          />
        </div>
      </Component>
    );
  }
}

export default connect(
  null,
  LeftTextRightPicture.mapDispatchToProps
)(LeftTextRightPicture);
