import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Header, ProfileCard, Password, ConfirmModal
} from 'components';
import { confirmChangePassword, changePassword } from 'store/actions';

class ProfilePage extends React.Component {
  static mapStateToProps = state => ({
    username: state.user.username,
    id: state.user.id,
    department: state.user.department,
    message: state.user.message,
    showChangePwdDialog: state.user.showChangePwdDialog
  });

  static mapDispatchToProps = dispatch => ({
    confirmChangePassword: state => dispatch(confirmChangePassword({ state })),
    changePassword: data => dispatch(changePassword.request(data))
  });

  static propTypes = {
    username: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    showChangePwdDialog: PropTypes.bool.isRequired,
    confirmChangePassword: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired
  };

  state = {
    password: '',
    reEnterPassword: ''
  };

  changePassword = () => {
    this.props.changePassword({
      password: this.state.password,
      reEnterPassword: this.state.reEnterPassword
    });
    this.clearInput();
  };

  clearInput = () => {
    this.setState({
      password: '',
      reEnterPassword: ''
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trim()
    });
  };

  render() {
    return (
      <>
        <Header link="/profile" title="个人资料" />
        <ProfileCard
          username={this.props.username}
          id={this.props.id}
          department={this.props.department}
          onClick={() => this.props.confirmChangePassword(true)}
        />
        <ConfirmModal
          show={this.props.showChangePwdDialog}
          title="修改密码"
          warning={this.props.message}
          confirm={this.changePassword}
          cancel={() => {
            this.props.confirmChangePassword(false);
            this.clearInput();
          }}
        >
          <Password name="password" value={this.state.password} onChange={this.handleChange} />
          <Password
            name="reEnterPassword"
            value={this.state.reEnterPassword}
            placeholder="确认密码"
            onChange={this.handleChange}
          />
        </ConfirmModal>
      </>
    );
  }
}

export default connect(
  ProfilePage.mapStateToProps,
  ProfilePage.mapDispatchToProps
)(ProfilePage);
