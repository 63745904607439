import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {
  HomeHeader, CardsContainer, AddButton, ConfirmModal
} from 'components';
import { getBriefingList, deleteBriefing, confirmDeleteBriefing } from 'store/actions';

class HomePage extends React.Component {
  static mapStateToProps = state => ({
    postsList: state.post.postsList,
    deletedIssue: state.post.deletedIssue,
    showDeleteModal: state.post.showDeleteModal
  });

  static mapDispatchToProps = dispatch => ({
    fetchData: () => dispatch(getBriefingList.request()),
    deleteBriefing: totalIssue => dispatch(deleteBriefing.request({ totalIssue })),
    confirmDeleteBriefing: (showDeleteModal, deletedIssue) => dispatch(
      confirmDeleteBriefing({ showDeleteModal, deletedIssue })
    )
  });

  static propTypes = {
    deletedIssue: PropTypes.number.isRequired,
    fetchData: PropTypes.func.isRequired,
    deleteBriefing: PropTypes.func.isRequired,
    confirmDeleteBriefing: PropTypes.func.isRequired,
    showDeleteModal: PropTypes.bool.isRequired,
    postsList: PropTypes.arrayOf(
      PropTypes.shape({
        totalIssue: PropTypes.number.isRequired,
        issue: PropTypes.number.isRequired,
        creator: PropTypes.string.isRequired,
        modifier: PropTypes.string.isRequired,
        createTime: PropTypes.string.isRequired,
        modifyTime: PropTypes.string.isRequired
      })
    ).isRequired
  };

  componentDidMount() {
    this.props.fetchData();
    window.addEventListener('storage', (event) => {
      if (event.key === 'saveBriefing') {
        this.props.fetchData();
      }
    });
  }

  newBriefing = (total, issue) => {
    window.open(`#/edit/?${qs.stringify({ total, issue })}`, 'new');
  };

  render() {
    return (
      <>
        <HomeHeader />
        <CardsContainer
          postsList={this.props.postsList && this.props.postsList}
          clickDeleteBriefingButton={this.props.confirmDeleteBriefing}
        />
        <AddButton
          onClick={() => {
            const { postsList } = this.props;
            let totalIssue = 1;
            let issue = 1;
            if (postsList.length) {
              totalIssue = postsList[0].totalIssue + 1;
              issue = postsList[0].issue + 1;
            }
            this.newBriefing(totalIssue, issue);
          }}
        />
        <ConfirmModal
          dangerous
          show={this.props.showDeleteModal}
          title="删除简报"
          text={`确定要删除第${this.props.deletedIssue}期简报吗？`}
          confirmMsg="确认删除"
          confirm={() => this.props.deleteBriefing(this.props.deletedIssue)}
          cancel={() => this.props.confirmDeleteBriefing(false, -1)}
        />
      </>
    );
  }
}

export default connect(
  HomePage.mapStateToProps,
  HomePage.mapDispatchToProps
)(HomePage);
