import React from 'react';
import { Box } from 'react-html-email';

const Component = ({ style, ...props }) => (
  <Box
    style={{
      width: '100%',
      margin: '25px 0', // 组件间的间距
      ...style
    }}
    {...props}
  />
);

export default Component;
