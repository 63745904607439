import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import style from './style.scss';

const ConfirmModal = props => (
  <Modal show={props.show} title={props.title} className={style.modal}>
    <p className={style.text}>{props.text}</p>
    {props.children}
    {/* 有警告先显示警告消息 */}
    {props.warning === '' ? (
      <p className={style.tip}>{props.tip}</p>
    ) : (
      <p className={style.warning}>{props.warning}</p>
    )}
    <div className={style.buttons}>
      <button
        type="button"
        className={props.dangerous ? style.emphasis : style.normal}
        onClick={props.cancel}
      >
        {props.cancelMsg}
      </button>
      <button
        type="button"
        className={props.dangerous ? style.normal : style.emphasis}
        onClick={props.confirm}
      >
        {props.confirmMsg}
      </button>
    </div>
  </Modal>
);

ConfirmModal.defaultProps = {
  children: null,
  text: '',
  tip: '',
  warning: '',
  show: false,
  title: '提示',
  confirmMsg: '确认',
  cancelMsg: '取消',
  dangerous: false
};

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  tip: PropTypes.string,
  warning: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  confirmMsg: PropTypes.string,
  cancelMsg: PropTypes.string,
  dangerous: PropTypes.bool,
  children: PropTypes.node
};

export default ConfirmModal;
