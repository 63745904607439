import React from 'react';
import {
  Email, renderEmail, Item, Box
} from 'react-html-email';
import renderElement from './render';
import Header from './mail/Header';
import Footer from './mail/Footer';

const renderSourceCode = props => renderEmail(
  <Email
    title="工作简报"
    width="800px"
  >
    <Item
      style={{
        backgroundColor: '#fcfcfc'
      }}
    >
      <Header {...props.config} />
      <Box
        align="center"
        style={{
          width: '720px',
          margin: '0 auto'
        }}
      >
        <Item>
          {props.content.map((item, index) => renderElement('mail', item, index))}
        </Item>
      </Box>
      <Footer />
    </Item>
  </Email>
);

export default renderSourceCode;
