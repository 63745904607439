import * as types from 'constants/actionTypes';

const initialState = {
  usersList: [],
  showAddUserModal: false,
  showDeleteModal: false,
  showChangeUserModal: false,
  deleteUserId: '',
  editId: '',
  editUsername: '',
  editDepartment: '',
  errorMessage: ''
};

const usersList = (state = initialState, action) => {
  switch (action.type) {
    /* 获取用户列表 */
    case types.GET_USERS_LIST.REQUEST:
      return state;
    case types.GET_USERS_LIST.SUCCESS:
      return {
        ...state,
        usersList: action.response
      };
    case types.GET_USERS_LIST.FAILURE:
      return state;

    case types.EDIT_USER_INFO:
      return {
        ...state,
        ...action.payload
      };

    /* 添加用户 */
    case types.CONFIRM_ADD_USER:
      return {
        ...state,
        ...action.payload,
        editId: '',
        editUsername: '',
        editDepartment: '',
        errorMessage: ''
      };
    case types.ADD_USER.REQUEST:
      return state;
    case types.ADD_USER.SUCCESS:
      return {
        ...state,
        editId: '',
        editUsername: '',
        editDepartment: '',
        showAddUserModal: false
      };
    case types.ADD_USER.FAILURE:
      return {
        ...state,
        errorMessage: action.error.message
      };

    /* 修改用户信息 */
    case types.CONFIRM_CHANGE_USER:
      return {
        ...state,
        ...action.payload,
        errorMessage: ''
      };
    case types.CHANGE_USER_INFO.REQUEST:
      return state;
    case types.CHANGE_USER_INFO.SUCCESS:
      return {
        ...state,
        editId: '',
        editUsername: '',
        editDepartment: '',
        showChangeUserModal: false
      };
    case types.CHANGE_USER_INFO.FAILURE:
      return {
        ...state,
        errorMessage: action.error.message
      };

    /* 删除用户 */
    case types.CONFIRM_DELETE_USER:
      return {
        ...state,
        ...action.payload
      };
    case types.DELETE_USER.REQUEST:
      return state;
    case types.DELETE_USER.SUCCESS:
      return {
        ...state,
        showDeleteModal: false
      };
    case types.DELETE_USER.FAILURE:
      return state;
    default:
      return state;
  }
};

export default usersList;
