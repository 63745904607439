import React from 'react';
import { Box, Item } from 'react-html-email';
import TopPictureBottomTextOneColumn from './TopPictureBottomTextOneColumn';

const TopPictureBottomTextThreeColumns = props => (
  <Box>
    <Item>
      <TopPictureBottomTextOneColumn
        {...props.children[0]}
        align={props.align}
        column={3}
      />
      <TopPictureBottomTextOneColumn
        {...props.children[1]}
        align={props.align}
        column={3}
        style={{
          marginLeft: '15px',
          marginRight: '15px'
        }}
      />
      <TopPictureBottomTextOneColumn
        {...props.children[2]}
        align={props.align}
        column={3}
      />
    </Item>
  </Box>
);

export default TopPictureBottomTextThreeColumns;
