import React from 'react';
import PropTypes from 'prop-types';
import { Box, Item } from 'react-html-email';
import mapFontSize from 'utils/mapFontSize';
import { Component, P, A, Img } from './base';

const Card = props => (
  <Component
    style={{
      borderTop: '2px solid #5c75ff'
    }}
  >
    <Item
      style={{
        padding: '15px 20px 0'
      }}
    >
      <Box
        style={{
          width: '180px',
          height: '120px',
          marginRight: '20px'
        }}
      >
        <Item>
          <Img
            src={props.imgSrc}
            alt="cover"
            width={180}
            height={120}
            style={{
              width: '180px',
              height: '120px'
            }}
          />
        </Item>
      </Box>
      <Box
        style={{
          width: '480px'
        }}
      >
        <Item>
          <A href={props.link}>
            {props.title.split('\n').map(sentence => (
              <P
                style={{
                  margin: '5px 0 0 0',
                  fontSize: mapFontSize(props.titleFontSize) || '22px',
                  fontWeight: 'bold',
                  color: '#323b6f'
                }}
              >
                {sentence}
              </P>
            ))}
          </A>
        </Item>
        <Item>
          <P
            style={{
              marginTop: '6px',
              fontSize: '14px',
              color: '#c73936'
            }}
          >
            {props.date}
          </P>
        </Item>
      </Box>
    </Item>
    <Item
      style={{
        padding: '0 15px 20px'
      }}
    >
      {props.text.split('\n').map(sentence => (
        <P
          style={{
            paddingTop: '16px',
            fontSize: mapFontSize(props.textFontSize) || '18px',
            color: '#333'
          }}
        >
          {sentence}
        </P>
      ))}
    </Item>
  </Component>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  titleFontSize: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textFontSize: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default Card;
