export default ((edition) => {
  switch (edition) {
    case 'cxcy':
      return {
        name: '华中科技大学创新创业教育领导小组办公室',
        location: '湖北省武汉市洪山区珞喻路1037号 华中科技大学启明学院',
        postcode: '430074',
        phone: ['027-87793423'],
        website: 'http://cxcy.hust.edu.cn',
        email: 'cxcy@hust.edu.cn',
        qrcode: '/static/cxcy/qrcode.jpg',
        banner: '/static/cxcy/briefing_header.png'
      };
    case 'qiming':
      return {
        name: '华中大启明学院',
        location: '湖北省武汉市洪山区珞喻路1037号 华中科技大学启明学院',
        postcode: '430074',
        phone: ['027-87558300', '027-87793421'],
        website: 'http://qiming.hust.edu.cn',
        email: 'qiming@hust.edu.cn',
        qrcode: '/static/qiming/qrcode.jpg',
        banner: '/static/qiming/briefing_header.png'
      };
    default:
      return {
        name: '华中科技大学创新创业教育领导小组办公室',
        location: '湖北省武汉市洪山区珞喻路1037号 华中科技大学启明学院',
        postcode: '430074',
        phone: ['027-87793423'],
        website: 'http://cxcy.hust.edu.cn',
        email: 'cxcy@hust.edu.cn',
        qrcode: '/static/cxcy/qrcode.jpg',
        banner: '/static/cxcy/briefing_header.png'
      };
  }
})(EDITION);
